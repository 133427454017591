.banking {
	height: 100vh;
	&_container {
		display: flex;
		padding: 3vw 2.5vw;
		width: 100%;
		&_leftside {
			width: 20%;
			margin-right: 5vw;
			border-start-start-radius: 0.5vw;
			border-start-end-radius: 0.5vw;
			&_top {
				margin-bottom: 2.5vw;
				&_header {
					display: flex;
					padding: 0.8vw;
					background: #256e61;
					border-start-start-radius: 0.5vw;
					border-start-end-radius: 0.5vw;
					img {
						width: 1.2vw;
						margin-right: 1vw;
					}
					p {
						color: #fff;
						font-size: 1vw;
						display: flex;
						align-items: center;
						font-weight: 500;
					}
				}
				&_content {
					background: #f9f9f9;
					display: flex;
					justify-content: space-around;
					padding: 2vw 0.5vw;
					p {
						font-size: 1vw;
					}
				}
			}
			&_bottom {
				margin-bottom: 2.5vw;
				&_header {
					display: flex;
					padding: 0.8vw;
					background: #256e61;
					border-start-start-radius: 0.5vw;
					border-start-end-radius: 0.5vw;
					img {
						width: 1vw;
						margin: 0.1vw 1vw 0 0;
					}
					p {
						color: #fff;
						font-size: 1vw;
						display: flex;
						align-items: center;
						font-weight: 500;
					}
				}
				&_content {
					background: #f9f9f9;
					&_data {
						background: #fefefe;
						padding: 0.5vw;
						p {
							font-size: 1vw;
						}
					}
					&_data:nth-child(even) {
						background: #f9f9f9;
						padding: 0.5vw;
						p {
							font-size: 1vw;
						}
					}
				}
			}
		}
		&_rightside {
			width: 60%;
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.banking {
		&_container {
			padding: 0;
			flex-direction: column;
			margin: 7vw 5vw;
			width: auto;
			&_leftside {
				width: 100%;
				margin: 0;
				&_top {
					margin-bottom: 6vw;
					&_header {
						padding: 2vw 0 2vw 3vw;
						img {
							width: 5vw;
							margin-right: 2vw;
						}
						p {
							font-size: 4vw;
						}
					}
					&_content {
						padding: 4vw 4vw 6vw;
						justify-content: space-between;
						p {
							font-size: 4.5vw;
							text-align: left;
						}
					}
				}
				&_bottom {
					margin-bottom: 8vw;
					&_header {
						padding: 2vw 0 2vw 3vw;
						img {
							width: 5vw;
							margin-right: 3vw;
						}
						p {
							font-size: 4vw;
						}
					}
					&_content {
						&_data {
							padding: 2vw 2vw;
							p {
								font-size: 4vw;
							}
						}
						&_data:nth-child(even) {
							padding: 2vw 2vw;
							p {
								font-size: 4vw;
							}
						}
					}
				}
			}
			&_rightside {
				width: 100%;
			}
		}
	}
	th.MuiTableCell-root {
		font-size: 3.5vw;
		background: #f9f9f9;
	}
	td.MuiTableCell-root {
		font-size: 3.5vw;
	}
}
