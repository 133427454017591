.ecommerce {
	&_container {
		padding: 3vw 2vw 8vw 4vw;
		display: flex;
		background: #ebebeb;
		&_slider {
			width: 15%;
			margin-right: 10vw;
			&_header {
				margin-bottom: 1.5vw;
				p {
					font-size: 1.5vw;
					font-weight: 500;
				}
			}
			&_heading {
				p {
					font-size: 1.3vw;
					font-weight: 500;
				}
			}
			&_head {
				margin-top: 1vw;
				p {
					font-size: 1.3vw;
					font-weight: 500;
				}
			}
			&_slider {
				margin: 1vw 0 1.6vw 0;
				background: #f3f3f3;
			}
			&_input {
				display: flex;
				justify-content: space-between;
				margin-bottom: 2vw;
				input {
					width: 35%;
				}
			}
			&_brand {
				margin-top: 1vw;
				input {
					width: 100%;
					border: 1px solid #d8d8d8;
					padding: 0.5vw;
					border-radius: 0.5vw;
					margin-bottom: 0.5vw;
				}
			}
		}
		&_header {
			display: none;
		}
		&_rightdata {
			width: 70%;
			&_sorting {
				display: flex;
				justify-content: space-between;
				width: 60%;
				margin-bottom: 3vw;
				.sort {
					font-size: 1.2vw;
					color: #7e7e7e;
				}
				.price {
					font-size: 1.2vw;
					color: #7085a6;
					text-decoration: underline;
				}
				p {
					font-size: 1.2vw;
					color: #7085a6;
				}
			}
			&_data {
				background: #fff;
				padding: 2vw;
				width: 95%;
				display: flex;
				margin-bottom: 4vw;
				&_image {
					margin-right: 1vw;
					img {
						width: 14vw;
						height: 14vw;
					}
				}
				&_details {
					display: flex;
					width: 100%;
					&_leftside {
						border-right: 1px solid #e4d7d7;
						width: 73%;
						&_heading {
							margin-bottom: 1vw;
							p {
								font-size: 1.3vw;
								color: #5e5e5e;
							}
						}
						&_rating {
							display: flex;
							&_star {
								display: flex;
								align-items: center;
								padding: 0.5vw;
								background: #256e61;
								p {
									font-size: 1vw;
									color: #fff;
									margin-right: 0.5vw;
								}
								img {
									width: 1vw;
									height: 1vw;
								}
							}
							&_data {
								display: flex;
								align-items: center;
								p {
									color: #8e8e8e;
									font-size: 1.2vw;
									margin-left: 2vw;
								}
							}
							&_details {
								margin-top: 2vw;
								margin-left: 1.5vw;
								li {
									list-style-type: disc;
									font-size: 1.1vw;
									color: #7c7c7c;
								}
							}
						}
					}
					&_rightside {
						padding-left: 1vw;
						&_pricing {
							margin-bottom: 1vw;
							p {
								font-size: 1.5vw;
								color: #939292;
							}
						}
						&_offer {
							display: flex;
							.strike {
								font-size: 1.3vw;
								margin-right: 1vw;
								color: #857575;
								text-decoration: line-through;
							}
							p {
								font-size: 1.3vw;
								color: #857575;
							}
						}
						&_launch {
							background: #256e61;
							margin-top: 1vw;
							margin-bottom: 1vw;
							p {
								color: #fff;
								padding: 1vw 0.2vw;
								font-size: 0.9vw;
								text-align: center;
							}
						}
						&_bottom {
							p {
								font-size: 0.9vw;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.ecommerce {
		&_container {
			background: none;
			padding: 0;
			margin: 3vw;
			display: flex;
			flex-direction: column;
			&_slider {
				display: none;
			}
			&_header {
				display: block;
				display: flex;
				justify-content: space-between;
			}
			&_filters {
				border: 1px solid #d2d2d2;
				border-radius: 10vw;
				width: 35%;
				padding: 3vw;
				margin: 4vw 0 5vw;
				h3 {
					text-align: center;
				}
			}
			&_sorting {
				border: 1px solid #d2d2d2;
				border-radius: 10vw;
				width: 35%;
				padding: 3vw;
				display: flex;
				margin: 4vw 0 5vw;
				justify-content: space-evenly;
				h3 {
					text-align: center;
				}
			}
			&_rightdata {
				width: 100%;
				&_sorting {
					display: none;
				}
				&_data {
					width: 94%;
					padding: 0;
					display: block;
					border: 1px solid #e3d4d4;
					border-radius: 2vw;
					padding: 4vw 3vw 3vw;
					&_image {
						margin: 0;
						img {
							width: 100%;
							height: 100%;
						}
					}
					&_details {
						display: block;
						&_leftside {
							width: 100%;
							border-right: none;
							border-bottom: 1px solid #e4d7d7;
							&_heading {
								margin: 2vw 0;
								p {
									font-size: 3.5vw;
								}
							}
							&_rating {
								margin-bottom: 4vw;
								&_details {
									margin-bottom: 2vw;
									li {
										font-size: 2.8vw;
									}
								}
								&_data {
									p {
										font-size: 2.5vw;
									}
								}
								&_star {
									padding: 1vw;
									p {
										font-size: 2.5vw;
										margin-right: 1.5vw;
									}
									img {
										width: 2.5vw;
										height: 2.5vw;
									}
								}
							}
						}
						&_rightside {
							padding: 0;
							margin: 3vw 0;
							display: flex;
							&_pricing {
								p {
									font-size: 6vw;
									margin-bottom: 2.5vw;
								}
							}
							&_offer {
								.strike {
									font-size: 3.3vw;
									margin-right: 2vw;
								}
								p {
									font-size: 3.3vw;
								}
							}
							&_launch {
								margin: 0 0 2.5vw;
								p {
									font-size: 3vw;
									padding: 2vw 0;
								}
							}
							&_bottom {
								p {
									font-size: 3.5vw;
									font-weight: 500;
								}
							}
							&_left {
								width: 50%;
								display: flex;

								flex-direction: column;
							}
							&_right {
								width: 50%;
								display: flex;

								flex-direction: column;
							}
						}
					}
				}
			}
		}
	}
}
