.mbpost {
	margin: 0.8vw 0 0.8vw 0;
	padding: 0.5vw;
	border-radius: 6px;
	border: 1px solid #ebebeb;
	background: #fff;
	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

	&_container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	&_mobile {
		width: 98%;
	}

	&_heading {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&_left {
			display: flex;
			align-items: center;

            img {
                width: 3vw;
                height: 3vw;
                border-radius: 50%;
                object-fit: cover;
            }

			&_user {
				padding: 0 0 0 1vw;

				&_name {
					display: flex;
					justify-content: center;
					align-items: center;

					h6 {
						font-size: 0.9vw;
						color: #797979;
					}

					p {
						font-size: 0.9vw;
						margin-left: 0.5vw;
						color: #848484;
					}
				}

				&_date {
					display: flex;
					align-items: center;
					padding: 0.2vw 0 0 0;

					p {
						font-size: 0.8vw;
						color: #61bcac;
						padding: 0 0.6vw 0 0;
					}

					span {
						font-size: 0.7vw;
						color: #787878;
					}
				}
			}
		}

		&_right {
			button {
        font-size: 1vw;
				color: #ada5a5;
				border-radius: 40px;
				border: 1px solid #dcbcbc;
				background: none;
				padding: 0.2vw 1vw;
			}

			img {
				width: 1vw;
				height: 1vw;
				margin-right: 0.3vw;
			}
		}
	}

	&_comments {
		display: flex;
		flex-direction: column;
		padding: 0 0 0.5vw 0;

		&_image {
			img {
				padding: 0 0 0.6vw 0;
				width: 100%;
				// height: 485px;
				//   object-fit: contain;
			}
		}

		&_current {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.5vw 0;

			&_count {
				display: flex;
				align-items: center;

				img {
					width: 1.4vw;
					height: 1.4vw;
					padding: 0;
				}

				p {
					padding: 0 0 0 0.5vw;
					font-size: 0.9vw;
					color: #6a6a6a;
				}
			}

			p {
				font-size: 0.9vw;
				color: #6a6a6a;
			}
		}
	}

	&_content {
		margin-bottom: 0.5vw;

		p {
			font-size: 0.9vw;
			color: #6a6a6a;
		}
	}

	&_bottom {
		width: 97%;
		display: flex;
		justify-content: space-between;
		//   border-top: 1px solid #d7d7d7;
		//   border-bottom: 1px solid #d7d7d7;
		padding: 1vw 0;

		&_like {
			display: flex;
			align-items: center;
			cursor: pointer;

			img {
				width: 1.4vw;
				height: 1.4vw;
				padding: 0;
			}

			.activelike {
				color: #38c9ff;
				padding: 0 0 0 0.5vw;
				font-size: 0.9vw;
			}

			.inactivelike {
				padding: 0 0 0 0.5vw;
				font-size: 0.9vw;
				color: #6a6a6a;
			}
		}

		&_comment {
			display: flex;
			align-items: center;

			img {
				width: 1.4vw;
				height: 1.4vw;
				padding: 0;
			}

			p {
				padding: 0 0 0 0.5vw;
				font-size: 0.9vw;
				color: #6a6a6a;
			}
		}

		&_share {
			display: flex;
			align-items: flex-end;

			img {
				width: 1.4vw;
				height: 1.4vw;
				padding: 0;
			}

			p {
				padding: 0 0 0 1vw;
				font-size: 0.9vw;
				color: #6a6a6a;
			}
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.mbpost {
		padding: 0;
		margin: 8vw 0;
		border: none;

		&_mobile {
			border-radius: 6px;
			// border: 1px solid #E8E6E6;
			background: #fff;
			// box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
			margin-bottom: 5vw;
		}

		&_heading {
			padding: 5px 15px 0 15px;

			&_left {
				img {
					width: 40px;
					height: 40px;
					margin-right: 10px;
				}

				&_user {
					&_name {
						h6 {
							font-size: 15px;
							// padding-bottom: 4px;
						}

						p {
							font-size: 12px;
							margin-left: 1.5vw;
						}
					}

					&_date {
						p {
							font-size: 12px;
							padding-right: 10px;
						}

						span {
							font-size: 11px;
						}
					}
				}
			}

			&_right {
				img {
					width: 10px;
					height: 10px;
				}
			}
		}

		&_content {
			p {
				font-size: 15px;
				padding: 2vw 3vw 0;
			}
		}

		&_comments {
			padding: 0;

			&_image {
				padding: 0px 8px;
				height: auto;

				img {
					// height: 180px;
					// object-fit: contain;
					// border-radius: 5px;
				}
			}

			&_current {
				background: white;
				padding: 0px 10px;

				&_count {
					img {
						width: 18px;
						height: 18px;
					}

					p {
						display: block !important;
						font-size: 17px;
					}
				}

				p {
					display: none;
				}
			}
		}

		&_bottom {
			background: white;
			border: none;
			padding: 0 0 20px 0;
			border-radius: 6px;
			cursor: pointer;

			&_like {
				border-radius: 40px;
				background: #f2f2f2;
				padding: 4px 10px;

				// border-radius: 10px;
				img {
					width: 20px;
					height: 20px;
				}

				.activelike {
					color: #38c9ff;
					font-size: 17px;
				}

				.inactivelike {
					font-size: 14px;
				}
			}

			&_comment {
				background: #f9f9f9;
				padding: 4px 10px;
				border-radius: 10px;

				img {
					width: 20px;
					height: 20px;
				}

				p {
					font-size: 14px;
				}
			}

			&_share {
				background: #f9f9f9;
				padding: 4px 10px;
				border-radius: 10px;

				img {
					width: 20px;
					height: 20px;
				}

				p {
					font-size: 14px;
				}
			}
		}
	}
}
