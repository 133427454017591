.headingPrivacy {
	background: #f5f5f5;

	&_container {
		display: flex;
		justify-content: center;
	}

	&_sections {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&_left {
		width: 100%;
		display: flex ;
		// align-items: center;

		.img {
			border-right: 1px solid #cbc3c3;
			padding-right: 1vw;
			cursor: pointer;
		}

		&_content {
			display: flex;

			padding-block: 1.2vw;

			align-items: center;
			cursor: pointer;

			.image,
			&>svg {
				display: flex;
				align-items: center;
				height: 22px;
				padding-left: 1vw;
				padding-right: 1vw;
			}

			h3 {
				color: #256F61;
				font-family: Inter;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.headingPrivacy {
		&_container {
			display: flex;
			justify-content: center;
			flex-direction: column;
		}

		&_sections {
			padding-inline: 12px;
			width: -webkit-fill-available;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&_left {
			display: flex;
			justify-content: space-between;
			margin-block: 0;

			.img {
				margin: 0;
				width: 100%;
				border: none;
			}

			&_content {
				display: none;

				h3 {
					font-size: 19px;
				}
			}
		}
	}
}