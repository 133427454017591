.engineering-f {
	display: flex;
	flex-direction: column;
	width: 100%;
	&_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 0 8vw 0;
	}
	&_sections {
		width: 80%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&_profile {
		width: 100%;
		display: flex;
		justify-content: center;
		border-bottom: 1px solid grey;
		&_container {
			padding: 6vw 0;
			width: 90%;
			display: flex;
			justify-content: space-between;
		}
		&_left {
			display: flex;
			&_image {
				img {
					width: 10vw;
					border-radius: 50%;
				}
			}
			&_user {
				padding: 0 0 0 2vw;
				display: flex;
				flex-direction: column;
				justify-content: center;
				h4 {
					font-size: 1.2vw;
					font-weight: 600;
				}
				&_location {
					display: flex;
					align-items: center;
					padding: 0.5vw 0;
					img {
						width: 1.2vw;
					}
					p {
						font-size: 0.8vw;
						padding: 0 0 0 0.5vw;
					}
				}
				&_job {
					display: flex;
					justify-content: space-between;
					padding: 1.2vw 0 0 0;
					&_left {
						display: flex;
						align-items: center;
						img {
							width: 2vw;
						}
						p {
							font-size: 0.8vw;
							padding: 0 0 0 0.5vw;
						}
					}
					&_right {
						display: flex;
						align-items: center;
						img {
							width: 2vw;
						}
						p {
							font-size: 0.8vw;
							padding: 0 0 0 0.5vw;
						}
					}
					&_mobile {
						display: none;
					}
				}
			}
		}
		&_right {
			button {
				border: 2px solid #61bcac;
				padding: 0.5vw 1vw;
				font-size: 0.9vw;
				font-weight: 400;
				margin: 0 1.2vw 0 0;
				border-radius: 1.6vw;
				background: white;
			}
			button:last-child {
				background: #61bcac;
				color: white;
				padding: calc(0.5vw + 2px) calc(1vw + 2px);
				margin: 0;
				font-weight: 200;
			}
		}
	}
	&_details {
		width: 100%;
		display: flex;
		justify-content: center;
		&_container {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
		&_left {
			width: 30%;
			padding: 6vw 0 0 0;
			display: flex;
			justify-content: center;
			&_container {
				width: 75%;
				display: flex;
				flex-direction: column;
			}
			&_earnings {
				display: flex;
				justify-content: space-between;
				&_sec:first-child {
					width: 36%;
				}
				&_sec {
					width: 30%;
					h5 {
						font-size: 1vw;
						font-weight: 600;
					}
					p {
						padding: 0.3vw 0 0 0;
						font-size: 0.8vw;
						font-weight: 600;
						color: #a8a8a8;
					}
				}
			}
			&_hours {
				padding: 3vw 0 0 0;
				&_heading {
					display: flex;
					align-items: center;
					h5 {
						font-size: 1vw;
						font-weight: 600;
						padding: 0 2vw 0 0;
					}
					img {
						width: 1.6vw;
					}
				}
				&_content {
					padding: 0.5vw 0 0 0;
					h6 {
						font-size: 0.8vw;
						font-weight: 400;
					}
				}
			}
			&_language {
				padding: 3vw 0 0 0;
				&_heading {
					display: flex;
					align-items: center;
					h5 {
						font-size: 1vw;
						font-weight: 600;
						padding: 0 2vw 0 0;
					}
					img {
						width: 1.6vw;
					}
				}
				&_content {
					padding: 0.5vw 0 0 0;
					h6 {
						font-size: 0.8vw;
						font-weight: 400;
					}
				}
			}
		}
		&_right {
			width: 70%;
			padding: 6vw 0 0 0;
			display: flex;
			border-left: 1px solid grey;
			&_container {
				width: 90%;
				display: flex;
				flex-direction: column;
			}
			&_heading {
				padding: 0 0 2vw 2vw;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid grey;
				&_left {
					width: 60%;
					h3 {
						font-size: 1.5vw;
					}
				}
				&_right {
					display: flex;
					align-items: center;
					h3 {
						font-size: 1.5vw;
						padding: 0 1vw 0 0;
					}
					img {
						width: 1.8vw;
						height: 1.8vw;
					}
				}
			}
			&_about {
				padding: 2vw 0 2vw 2vw;
				p {
					font-size: 1vw;
				}
			}
			&_history {
				width: 90%;
				padding: 2vw 0 2vw 2vw;
				&_heading {
					h4 {
						font-size: 1.2vw;
						font-weight: 500;
					}
				}
				&_data {
					&_tabs {
						display: flex;
						border-bottom: 1px solid grey;
						margin: 2vw 0;
					}
					&_tab {
						padding: 0 2vw 0 0;
						h5 {
							font-size: 0.9vw;
							font-weight: 400;
							border-bottom: 3px solid #61bcac;
							padding: 0 0 0.2vw 0;
						}
						.active {
							border-bottom: none;
						}
					}
					&_sec {
						padding: 0 0 2vw 0;
						&_heading {
							h6 {
								font-size: 1vw;
								font-weight: 500;
								color: #61bcac;
							}
						}
						&_content {
							p {
								font-size: 0.9vw;
								padding: 1vw 0;
								color: #878787;
							}
						}
						&_details {
							display: flex;
							align-items: center;
							&_stars {
								display: flex;
								img {
									width: 1.2vw !important;
									height: 1.2vw !important;
									margin: 0 0.5vw 0 0;
								}
							}
							p {
								font-size: 0.7vw;
								padding: 0 1vw;
								color: #737373;
							}
							img {
								width: 1.5vw;
								height: 1.5vw;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.engineering-f {
		display: flex;
		flex-direction: column;
		width: 100%;
		&_container {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 0 8vw 0;
		}
		&_sections {
			width: 80%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&_profile {
			width: 100%;
			display: flex;
			justify-content: center;
			border-bottom: 0;
			&_container {
				width: 100%;
				flex-direction: column;
			}
			&_left {
				&_image {
					img {
						width: 84px;
						height: 84px;
						flex-shrink: 0;
					}
				}
				&_user {
					padding: 0 0 0 2vw;
					display: flex;
					flex-direction: column;
					justify-content: center;
					h4 {
						font-size: 23px;
					}
					&_location {
						display: flex;
						align-items: center;
						padding: 0.5vw 0;
						img {
							width: 22px;
							height: 22px;
							flex-shrink: 0;
						}
						p {
							font-size: 10px;
							padding: 0 0 0 0.5vw;
						}
					}
					&_job {
						display: none;
						&_mobile {
							display: flex;
							justify-content: space-between;
							width: 90%;
							margin-top: 2vw;
							margin-bottom: 6vw;
							p {
								font-size: 14px;
							}
							img {
								width: 19px;
								height: 19px;
								flex-shrink: 0;
							}
						}
					}
				}
			}
			&_right {
				display: flex;
				justify-content: space-between;
				button {
					padding: 3vw 2vw;
					font-size: 14px;
					border-radius: 20px;
					width: 48%;
				}
				button:last-child {
					background: #61bcac;
					color: white;
					padding: calc(0.5vw + 2px) calc(1vw + 2px);
					margin: 0;
					font-weight: 200;
				}
			}
		}
		&_details {
			&_container {
				flex-direction: column;
			}
			&_left {
				width: 100%;
				padding: 6vw 0;
				&_container {
					width: 100%;
					display: flex;
					flex-direction: column;
				}
				&_earnings {
					display: flex;
					justify-content: space-between;
					&_sec:first-child {
						width: 36%;
					}
					&_sec {
						width: 30%;
						h5 {
							font-size: 16px;
						}
						p {
							font-size: 14px;
							padding: 0.5rem 0 1rem;
						}
					}
				}
				&_hours {
					padding: 3vw 0 0 0;
					&_heading {
						display: flex;
						align-items: center;
						width: 65%;
						justify-content: space-between;
						h5 {
							font-size: 16px;
						}
						img {
							width: 24px;
							height: 24px;
							flex-shrink: 0;
						}
					}
					&_content {
						padding: 0.8rem 0 1rem;
						h6 {
							font-size: 15px;
							font-weight: 400;
						}
					}
				}
				&_language {
					padding: 3vw 0 0 0;
					&_heading {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 65%;
						h5 {
							font-size: 17px;
						}
						img {
							width: 24px;
							height: 24px;
							flex-shrink: 0;
						}
					}
					&_content {
						padding: 0.8rem 0 1rem;
						h6 {
							font-size: 0.8vw;
							font-weight: 400;
						}
					}
				}
			}
			&_right {
				width: 100%;
				padding: 6vw 0 0 0;
				display: flex;
				border-top: 1px solid grey;
				border-left: 0;
				&_container {
					width: 100%;
					display: flex;
					flex-direction: column;
				}
				&_heading {
					padding: 2vw 0;
					display: flex;
					justify-content: space-between;
					border-bottom: 0;
					&_left {
						width: 60%;
						h3 {
							font-size: 20px;
						}
					}
					&_right {
						width: 30%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						h3 {
							font-size: 20px;
						}
						img {
							width: 24px;
							height: 24px;
							flex-shrink: 0;
						}
					}
				}
				&_about {
					border-bottom: 1px solid grey;
					padding: 4vw 0 8vw;
					p {
						font-size: 16px;
					}
				}
				&_history {
					width: 100%;
					padding: 2vw 0;
					margin: 3rem 0 2rem;
					&_heading {
						h4 {
							font-size: 20px;
						}
					}
					&_data {
						&_tabs {
							width: 94%;
							display: flex;
							justify-content: space-between;
							border-bottom: 1px solid grey;
							margin: 5vw 0 8vw;
						}
						&_tab {
							padding: 0 2vw 0 0;
							h5 {
								font-size: 15px;
								border-bottom: 5px solid #61bcac;
								padding: 0 0 3.2vw 0;
							}
							.active {
								border-bottom: none;
							}
						}
						&_sec {
							padding: 0 0 2vw 0;
							&_heading {
								h6 {
									font-size: 19px;
								}
							}
							&_content {
								p {
									font-size: 16px;
									padding: 2.5vw 0 1vw;
								}
							}
							&_details {
								display: flex;
								justify-content: space-between;
								align-items: center;
								margin: 1.5rem 0 2rem;
								&_stars {
									display: flex;
									img {
										width: 20px !important;
										height: 20px !important;
									}
								}
								p {
									font-size: 12px;
								}
								img {
									width: 30px;
									height: 30px;
								}
							}
						}
					}
				}
			}
		}
	}
}
