.itfreelancerprivate {
	display: flex;
	flex-direction: column;
	width: 100%;

	&_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 0 8vw 0;
	}

	&_sections {
		width: 80%;
		display: flex;
		flex-direction: column;
		//   align-items: center;
		&_title {
			font-size: 1.4vw;
			color: #256f61;
			margin: 2vw 0 0 2vw;
			font-weight: 500;
		}
	}

	&_profile {
		width: 100%;
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #e2e2e2;

		&_container {
			padding: 4vw 0 1vw;
			width: 95%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&_left {
			display: flex;

			&_image {
				position: relative;

				img {
					width: 10vw;
					border-radius: 50%;
				}
				.imgedit {
					width: 20%;
					position: absolute;
					bottom: 0.5vw;
					right: 1vw;
				}
			}

			&_user {
				padding: 0 0 0 2vw;
				display: flex;
				flex-direction: column;
				justify-content: center;

				h4 {
					font-size: 1.2vw;
					font-weight: 600;
				}
				&_title {
					display: flex;
					align-items: center;

					img {
						width: 5%;
						height: fit-content;
						margin-left: 1vw;
					}
				}

				&_location {
					display: flex;
					align-items: center;
					padding: 0.5vw 0;

					img {
						width: 1.2vw;
					}

					p {
						font-size: 0.8vw;
						padding: 0 0 0 0.5vw;
					}
				}

				&_job {
					display: flex;
					justify-content: space-between;
					padding: 1.2vw 0 0 0;

					&_left {
						display: flex;
						align-items: center;

						img {
							width: 2vw;
						}

						p {
							font-size: 0.8vw;
							padding: 0 0 0 0.5vw;
						}
					}

					&_right {
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							width: 2vw;
						}

						p {
							font-size: 0.8vw;
							padding: 0 0 0 0.5vw;
						}
					}

					&_mobile {
						display: none;
					}
				}
			}
		}

		&_right {
			button {
				border: 2px solid #61bcac;
				padding: 0.5vw 1vw;
				font-size: 0.9vw;
				font-weight: 400;
				margin: 0 1.2vw 0 0;
				border-radius: 1.6vw;
				background: white;
			}

			// button:last-child {
			//   background: #61bcac;
			//   color: white;
			//   padding: calc(0.5vw + 2px) calc(1vw + 2px);
			//   margin: 0;
			//   font-weight: 200;
			// }
		}
	}

	&_details {
		width: 100%;
		display: flex;
		justify-content: center;

		&_container {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		&_left {
			width: 30%;
			padding: 6vw 0 0 0;
			display: flex;
			justify-content: center;

			&_container {
				width: 75%;
				display: flex;
				flex-direction: column;
			}

			&_earnings {
				display: flex;
				justify-content: space-between;

				&_sec:first-child {
					width: 36%;
				}

				&_sec {
					width: 30%;

					h5 {
						font-size: 1vw;
						font-weight: 600;
					}

					p {
						padding: 0.3vw 0 0 0;
						font-size: 0.8vw;
						font-weight: 600;
						color: #a8a8a8;
					}
				}
			}

			&_hours {
				padding: 3vw 0 0 0;

				&_heading {
					display: flex;
					align-items: center;
					justify-content: space-between;
					&_left {
						display: flex;
						justify-content: space-between;
						width: 55%;
					}

					h5 {
						font-size: 1vw;
						font-weight: 600;
						//   padding: 0 2vw 0 0;
					}

					img {
						width: 1.2vw;
					}
				}

				&_content {
					padding: 0.5vw 0 0 0;

					h6 {
						font-size: 0.8vw;
						font-weight: 400;
					}
				}
			}

			&_language {
				padding: 3vw 0 0 0;

				&_heading {
					display: flex;
					align-items: center;
					justify-content: space-between;
					&_left {
						display: flex;
						justify-content: space-between;
						width: 55%;
					}

					h5 {
						font-size: 1vw;
						font-weight: 600;
						padding: 0 2vw 0 0;
					}

					img {
						width: 1.2vw;
					}
				}

				&_content {
					padding: 0.5vw 0 0 0;

					h6 {
						font-size: 0.8vw;
						font-weight: 400;
					}
				}
			}
			&_other {
				border-radius: 6px;
				border: 1px solid #ebebeb;
				background: #fff;
				box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
				margin: 2vw 0;
				padding: 1vw;
				line-height: 2vw;
				&_title {
					display: flex;
					justify-content: space-between;
					align-items: center;
					h3 {
						font-size: 1.2vw;
						color: #000;

						font-family: Poppins;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
					p {
						color: #868d8c;
						font-size: 1vw;
						font-family: Poppins;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						text-decoration-line: underline;
					}
				}

				&_content {
					font-size: 0.9vw;
					margin-top: 1vw;

					p {
						color: #828282;
					}

					span {
						color: #1c1b1b;
					}
				}
			}
		}

		&_right {
			width: 70%;
			padding: 6vw 0 0 0;
			display: flex;
			// border-left: 1px solid grey;

			&_container {
				width: 90%;
				display: flex;
				flex-direction: column;
			}

			&_heading {
				padding: 0 0 2vw 2vw;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				//   border-bottom: 1px solid grey;
				&_sec1 {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
				}
				&_left {
					width: 60%;

					h3 {
						font-size: 1.5vw;
					}
				}

				&_right {
					display: flex;
					align-items: center;
					&_sec1 {
						display: flex;
						h3 {
							font-size: 1.5vw;
							padding: 0 1vw 0 0;
						}

						img {
							width: 1.8vw;
							height: 1.8vw;
						}
					}
				}
				.imgedit {
					margin-left: 1vw;
					width: 1.2vw;
					height: fit-content;
				}
			}

			&_about {
				display: flex;
				padding: 0 0 0 2vw;

				p {
					font-size: 1vw;

					span {
						color: #61bcac;

						font-family: Poppins;
						font-size: 1vw;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						text-decoration-line: underline;
						text-underline-offset: 2px;
					}
				}
				img {
					width: 1.2vw;
					height: fit-content;
				}
			}

			&_history {
				width: 90%;
				padding: 2vw 0 2vw 2vw;

				&_heading {
					h4 {
						font-size: 1.2vw;
						font-weight: 500;
					}
				}

				&_data {
					&_tabs {
						display: flex;
						border-bottom: 1px solid grey;
						margin: 2vw 0;
					}

					&_tab {
						padding: 0 2vw 0 0;

						h5 {
							font-size: 0.9vw;
							font-weight: 400;
							border-bottom: 3px solid #61bcac;
							padding: 0 0 0.2vw 0;
						}

						.active {
							border-bottom: none;
						}
					}

					&_sec {
						padding: 0 0 2vw 0;

						&_heading {
							h6 {
								font-size: 1vw;
								font-weight: 500;
								color: #61bcac;
							}
						}

						&_content {
							p {
								font-size: 0.9vw;
								padding: 1vw 0;
								color: #878787;
							}
						}

						&_details {
							display: flex;
							align-items: center;

							&_stars {
								display: flex;

								img {
									width: 1.2vw !important;
									height: 1.2vw !important;
									margin: 0 0.5vw 0 0;
								}
							}

							p {
								font-size: 0.7vw;
								padding: 0 1vw;
								color: #737373;
							}

							img {
								width: 1.5vw;
								height: 1.5vw;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.itfreelancerprivate {
		display: flex;
		flex-direction: column;
		width: 100%;

		&_container {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 0 8vw 0;
		}

		&_sections {
			width: 85%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		&_profile {
			width: 100%;
			display: flex;
			justify-content: center;
			border-bottom: 0;
			margin-top: 5vw;

			&_container {
				width: 95%;
				flex-direction: column;
				align-items: flex-start;
			}

			&_left {
				&_image {
					img {
						width: 84px;
						height: 84px;
						flex-shrink: 0;
					}
					.imgedit {
						height: fit-content;
						width: 30%;
						position: absolute;
						bottom: 1vw;
						right: 1vw;
					}
				}

				&_user {
					padding: 0 0 0 2vw;
					display: flex;
					flex-direction: column;
					justify-content: center;

					h4 {
						font-size: 23px;
					}

					&_location {
						display: flex;
						align-items: center;
						padding: 0.5vw 0;

						img {
							width: 22px;
							height: 22px;
							flex-shrink: 0;
						}

						p {
							font-size: 10px;
							padding: 0 0 0 0.5vw;
						}
					}

					&_job {
						display: none;

						&_mobile {
							display: flex;
							justify-content: space-between;
							width: 90%;
							margin-top: 3vw;
							margin-bottom: 6vw;

							p {
								font-size: 14px;
								padding: 0 2vw;
							}

							img {
								width: 19px;
								height: 19px;
								flex-shrink: 0;
							}
						}
					}
				}
			}

			&_right {
				width: 100%;
				display: flex;
				justify-content: flex-end;

				button {
					padding: 2vw;
					font-size: 14px;
					border-radius: 30px;
					// width: 48%;
				}

				//   button:last-child {
				//     background: #61bcac;
				//     color: white;
				//     padding: calc(0.5vw + 2px) calc(1vw + 2px);
				//     margin: 0;
				//     font-weight: 200;
				//   }
			}
		}

		&_details {
			&_container {
				flex-direction: column;
			}

			&_left {
				width: 100%;

				padding: 6vw 0;

				&_container {
					width: 100%;
					display: flex;
					flex-direction: column;
				}

				&_earnings {
					display: flex;
					justify-content: space-between;

					&_sec:first-child {
						width: 36%;
					}

					&_sec {
						width: 30%;

						h5 {
							font-size: 16px;
						}

						p {
							font-size: 14px;
							padding: 0.5rem 0 1rem;
						}
					}
				}

				&_hours {
					padding: 3vw 0 0 0;

					&_heading {
						display: flex;
						align-items: center;
						//   width: 65%;
						justify-content: space-between;

						h5 {
							font-size: 16px;
						}

						img {
							width: 24px;
							height: 24px;
							flex-shrink: 0;
						}
						.imgedit {
							width: 17px;
							height: 17px;
							flex-shrink: 0;
						}
					}

					&_content {
						padding: 0.8rem 0 2rem;

						h6 {
							font-size: 15px;
							font-weight: 400;
						}
					}
				}

				&_language {
					padding: 3vw 0 0 0;
					border-bottom: 1px solid grey;

					&_heading {
						display: flex;
						align-items: center;
						justify-content: space-between;
						//   width: 65%;

						h5 {
							font-size: 17px;
						}

						img {
							width: 24px;
							height: 24px;
							flex-shrink: 0;
						}
						.imgedit {
							width: 17px;
							height: 17px;
							flex-shrink: 0;
						}
					}

					&_content {
						padding: 0.8rem 0 1rem;

						h6 {
							font-size: 0.8vw;
							font-weight: 400;
						}
					}
				}
				&_other {
					// width: 100%;
					margin: 5vw 2vw 1vw;
					padding: 5vw 3vw;
					line-height: 5.5vw;

					&_title {
						h3 {
							font-size: 22px;
						}
						p {
							font-size: 13px;
						}
					}
					&_content {
						p {
							font-size: 15px;
						}
					}
				}
			}

			&_right {
				width: 100%;
				padding: 0;
				display: flex;
				//   border-top: 1px solid grey;
				border-left: 0;

				&_container {
					width: 100%;
					display: flex;
					flex-direction: column;
				}

				&_heading {
					flex-direction: column-reverse;
					align-items: flex-end;
					padding: 2vw 0;
					display: flex;
					justify-content: space-between;
					border-bottom: 0;

					&_left {
						width: 60%;

						h3 {
							font-size: 20px;
						}
					}

					&_right {
						width: 30%;
						display: flex;
						align-items: center;
						justify-content: space-between;

						&_sec1 {
							width: 100%;
							justify-content: space-between;

							h3 {
								font-size: 20px;
							}

							img {
								width: 24px;
								height: 24px;
								flex-shrink: 0;
							}
						}
					}

					.imgedit {
						// width: auto;
						width: 17px;
						height: 17px;
						margin-bottom: 3vw;
					}
				}

				&_about {
					border-bottom: 1px solid grey;
					padding: 4vw 0 8vw;
					flex-direction: column-reverse;
					align-items: flex-end;
					&_more {
						display: none;
					}
					p {
						font-size: 16px;

						span {
							font-size: 16px;
						}
					}
					.imgedit {
						// width: auto;
						width: 17px;
						height: 17px;
						margin-bottom: 3vw;
					}
				}

				&_history {
					width: 100%;
					padding: 2vw 0;
					margin: 3rem 0 2rem;

					&_heading {
						h4 {
							font-size: 20px;
						}
					}

					&_data {
						&_tabs {
							width: 94%;
							display: flex;
							justify-content: space-between;
							border-bottom: 1px solid grey;
							margin: 5vw 0 8vw;
						}

						&_tab {
							padding: 0 2vw 0 0;

							h5 {
								font-size: 15px;
								border-bottom: 5px solid #61bcac;
								padding: 0 0 3.2vw 0;
							}

							.active {
								border-bottom: none;
							}
						}

						&_sec {
							padding: 0 0 2vw 0;

							&_heading {
								h6 {
									font-size: 19px;
								}
							}

							&_content {
								p {
									font-size: 16px;
									padding: 2.5vw 0 1vw;
								}
							}

							&_details {
								display: flex;
								justify-content: space-between;
								align-items: center;
								margin: 1.5rem 0 2rem;

								&_stars {
									display: flex;

									img {
										width: 20px !important;
										height: 20px !important;
									}
								}

								p {
									font-size: 12px;
								}

								img {
									width: 30px;
									height: 30px;
								}
							}
						}
					}
				}
			}
		}
	}
}
