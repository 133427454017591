.shortsSearchTab {
	.MuiTabs-flexContainer {
		margin-bottom: 0px !important;
		border-bottom: 1px solid grey !important;
	}

	.Mui-selected {
		color: #61BCAC !important;
	}

	@media screen and (min-width: 320px) and (max-width: 760px) {


		.MuiTabs-flexContainer {
			width: 90%;
			margin-bottom: 0px !important;
			border-bottom: 1px solid grey !important;
		}

		.MuiTabs-scroller {
			display: flex;
			justify-content: center;
		}
		.MuiTabPanel-root {
			background: #fff;
			padding: 0 !important;
			display: flex;
			justify-content: center;
			margin-bottom: 10px;
		}
	}
}

.user {
	&_container {
		background-color: #ffffff;
		width: 64%;
		margin-top: 2vw;
		box-shadow: 0 0 8px #b9babd;
		border-radius: 6px;
	}

	&_section {
		&_cards {
			padding: 1vw 1vw 0 1vw;

			&_content {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #E4E4E4;

				&_center {
					display: flex;
					justify-content: center;
					cursor: pointer;

					&_image {
						margin-right: 1.5vw;

						img {
							width: 5.2vw;
							height: 5.2vw;
							border-radius: 50%;
						}
					}

					&_content {
						display: flex;
						flex-direction: column;
						align-self: center;

						h2 {
							display: flex;
							align-items: center;
							font-size: 1.1vw;
							font-weight: 600;
							color: #000;
							margin-bottom: 0.3vw;
						}

						h5 {
							font-size: 0.9vw;
							color: #5e5e5e;
							font-weight: 400;
							font-style: normal;
							padding: 0 5px 0 0;


						}

						p {
							color: #8e8e8e;
							font-size: 0.8vw;
							font-weight: 400;
							padding: 0.5vw 0;
						}
					}

					&_follow {
						display: flex;
						justify-content: center;
						align-items: center;

						button {
							width: 8vw;
							cursor: pointer;
							color: white;
							font-size: 1vw;
							padding: 0.5vw 0;
							border-radius: 1.2vw;
							border: 1px solid transparent;
							background: #61BCAC;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.user {
		&_container {
			background-color: #ffffff;
			width: 90%;
			margin-top: 20px;
			box-shadow: 0 0 8px #b9babd;
			border-radius: 6px;
		}

		&_section {
			&_cards {
				padding: 3vw;

				&_content {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					// align-items: center;
					border-bottom: none;

					&_center {
						display: flex;
						justify-content: center;
						cursor: pointer;
						width: 100%;

						&_image {
							margin-right: 1.5vw;

							img {
								width: 4rem;
								height:4rem;
								border-radius: 50%;
							}
						}

						&_content {
							display: flex;
							flex-direction: column;
							align-self: center;

							h2 {
								display: flex;
								align-items: center;
								font-size: 1rem;
								font-weight: 600;
								color: #000;
								margin-bottom: 0.3vw;
							}

							h5 {
								font-size: 0.8rem;
								color: #5e5e5e;
								font-weight: 400;
								font-style: normal;
								padding: 0;


							}

							p {
								display: none;
							}
						}

						&_follow {
							display: flex;
							justify-content: center;
							align-items: flex-start;
							button {
								width: 18vw;
								cursor: pointer;
								color: white;
								font-size: 4vw;
								padding: 1.5vw ;
								border-radius: 4vw;
								border: 1px solid transparent;
								background: #61BCAC;
							}
						}
					}
				}
			}
		}
	}
}