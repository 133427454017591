.chatbotheader {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f8f7f7;
	height: 5.5vw;
	&_main {
		display: flex;
		align-items: center;
		width: 95%;
		&_left {
			width: 15%;
			display: flex;
			align-items: center;
			img {
				cursor: pointer;
				width: 90%;
			}
			p {
				color: #256f61;
				font-family: Inter;
				font-size: 1.2vw;
				font-style: normal;
				font-weight: 700;
				line-height: 157%;
			}
		}
		&_center {
			border-right: 1px solid #cbc3c3;
			height: 3vh;
			margin-right: 0.5vw;
		}
		&_right {
			display: flex;
			align-items: center;
			img {
				width: 20%;
			}
			p {
				color: #256f61;
				font-family: Inter;
				font-size: 1.2vw;
				font-style: normal;
				font-weight: 700;
				line-height: 157%;
			}
		}
	}
}
.chatbotmobileheader {
	display: none;
}
.chatbot {
  display: flex;
  justify-content: center;
  &_sidenav {
    background-color: #61bcac;
    width: 25%;
    height: calc(100vh - 5.5vw);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &_main {
      padding-left: 1vw;
      width: 95%;
      &_one {
        margin-top: 2vw;
        display: flex;
        align-items: center;
        .newchat {
          border: 1px solid #f1f1f1;
          padding: 1vw 1vw;
          width: 70%;
          border-radius: 5px;
          p {
            color: #fff;
            font-family: Inter;
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .newsend {
          border: 1px solid #f1f1f1;
          width: 10%;
          margin-left: 0.5vw;
          border-radius: 5px;
          padding: 0.5vw;
          img {
            width: 100%;
          }
        }
      }
      &_two {
        .chatday {
          margin-top: 2vw;
          p {
            color: #fff;
            font-family: Inter;
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .chathistory {
          margin-top: 0.7vw;
          display: flex;
          align-items: center;
          img {
            color: #fbfbfb;
          }
          p {
            margin-left: 0.5vw;
            color: #fff;
            font-family: Inter;
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      &_three {
        margin-top: 1vw;
        .chatday {
          margin-top: 2vw;
          p {
            color: #fff;
            font-family: Inter;
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .chathistory {
          margin-top: 0.7vw;
          display: flex;
          align-items: center;
          img {
            color: #fbfbfb;
          }
          p {
            margin-left: 0.5vw;
            color: #fff;
            font-family: Inter;
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
    &_bottom {
      margin-bottom: 1vw;
      &_four {
        border-top: 1px solid #f0f0f0;
        .fourbottom {
          padding-left: 1vw;
          display: flex;
          align-items: center;
          margin-top: 1vw;
          span {
            background: #fbfbfb;
            padding: 1vw;
            color: black;
            font-size: 1vw;
          }
          p {
            margin-left: 1vw;
            color: #fff;
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
  &_sidenavmobile {
    display: none;
  }
  &_main {
    display: flex;
    justify-content: center;
    height: calc(100vh - 5.5vw);
    width: 75%;
    align-items: flex-end;
    &_container {
      width: 100%;
      // overflow-y: scroll;
      height: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
    &_suggestions {
      display: flex;
      justify-content: space-between;
      width: 70%;
      overflow-y: scroll;
      flex-direction: column;
      .qanda {
        margin-bottom: 1.5vw;
      }
      .qanda:last-child {
        margin-bottom: 0vw;
      }
      .question {
        font-size: 1.2vw;
        font-weight: 500;
        margin-bottom: 0.3vw;
      }
      .answer {
        font-size: 0.9vw;
        color: #716666;
      }
      .tempdata {
        display: flex;
        justify-content: space-between;
      }
      &_box {
        width: 40%;
        border-radius: 8px;
        border: 1px solid #b1a9a9;
        padding: 1.5rem;
        margin: 1rem;
        h5 {
          font-size: 15px;
          font-weight: 600;
          color: #716666;
        }
        p {
          font-size: 15px;
          font-weight: 400;
          color: #716666;
        }
      }
    }
    .loader {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      -webkit-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    &_message {
      width: 70%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem;
      input {
        height: 5rem;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #b1a9a9;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
        padding: 0 3rem;
        font-size: 1vw;
        word-wrap: break-word;
      }
      .chatbotmsgimg {
        position: absolute;
        left: 1rem;
      }
      .chatbotsendimg {
        cursor: pointer;
        position: absolute;
        right: 1.4rem;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
	.chatbotheader {
		display: none;
	}
	.chatbotmobileheader {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f8f7f7;
		padding-bottom: 3vw;
		&_main {
			display: flex;
			align-items: center;
			width: 95%;
			flex-direction: column;
			padding: 2vw 0;
			&_left {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 90%;
				.text {
					display: flex;
					align-items: center;
					width: 50%;
					.chatbuzzimg {
						width: 62px;
						height: 53.711px;
					}
					img {
						width: 100%;
					}
				}
				.chatbotmobilesearchimg {
					width: 18px;
					height: 18px;
					img {
						width: 100%;
					}
				}
			}
			&_right {
				margin-top: 2vw;
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 80%;
				.text {
					display: flex;
					align-items: center;
					.chatchatimg {
						width: 24.73px;
						height: 25.901px;
					}
					img {
						width: 100%;
					}
					p {
						color: #256f61;
						font-size: 17px;
						font-style: normal;
						font-weight: 700;
						line-height: 157%;
						margin-left: 2vw;
					}
				}
				.chatbotmobilemenuimg {
					width: 28px;
					height: 14px;
					img {
						margin-left: 3vw;
						width: 100%;
					}
				}
			}
		}
	}
	.chatbot {
		&_sidenav {
			display: none;
		}
	}
	.chatbot {
		&_sidenavmobile {
			position: absolute;
			z-index: 999;
			background-color: #61bcac;
			width: 87%;
			height: 84vh;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			top: auto;
			bottom: 1vw;
			&_main {
				padding-left: 2vw;
				width: 95%;
				&_one {
					margin-top: 2vw;
					display: flex;
					align-items: center;
					.newchat {
						border: 1px solid #f1f1f1;
						padding: 2.5vw 1vw;
						width: 85%;
						border-radius: 5px;
						p {
							color: #fff;
							font-family: Inter;
							font-size: 4vw;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
						}
					}
					.newsend {
						position: absolute;
						right: -11vw;
						border: 1px solid #adacac;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 12%;
						img {
							width: 100%;
						}
					}
				}
				&_two {
					.chatday {
						margin-top: 5vw;
						p {
							color: #fff;
							font-family: Inter;
							font-size: 4vw;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
					}
					.chathistory {
						margin-top: 3vw;
						display: flex;
						align-items: center;
						img {
							color: #fbfbfb;
						}
						p {
							margin-left: 3vw;
							color: #fff;
							font-family: Inter;
							font-size: 3.5vw;
							font-style: normal;
							font-weight: 300;
							line-height: normal;
						}
					}
				}
				&_three {
					margin-top: 2vw;
					.chatday {
						margin-top: 7vw;
						p {
							color: #fff;
							font-family: Inter;
							font-size: 4vw;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
					}
					.chathistory {
						margin-top: 3vw;
						display: flex;
						align-items: center;
						img {
							color: #fbfbfb;
						}
						p {
							margin-left: 3vw;
							color: #fff;
							font-family: Inter;
							font-size: 3.5vw;
							font-style: normal;
							font-weight: 300;
							line-height: normal;
						}
					}
				}
			}
		}
		&_main {
			height: auto;
			width: 100%;
			&_suggestions {
				width: 100%;
				flex-direction: column;
				& .tempdata {
					flex-direction: column;
				}
				&_box {
					width: auto;
				}
			}
			&_message{
				width: -webkit-fill-available;
				input{
					font-size: 15px;
				}
			}
		}
	}
}
