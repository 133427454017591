.notif {
    &_container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &_top {
        width: 100%;

        &_dp {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            img {
                width: 7vw;
                height: 7vw;
                margin-left: 1vw;

            }
        }

        &_desc {

            h5 {
                margin: 1vw 0 0.5vw;
            }


            &_follow {
                display: flex;

                p {
                    margin-right: 1.5vw;
                    // font-size: 1vw;
                    color: #848484;
                }

            }
        }
    }

    &_bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
        padding: 2vw;
				
        &_container {
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					row-gap: 24px;
        }

        &_card {
            display: flex;

            img {
                width: 5vw;
                height: 5vw;
            }

            &_right {
                margin-left: 2vw;

                h6 {
                    font-size: 1.2vw;
                    color: #6D6D6D;
                }

                p {
                    margin-top: 1vw;
                    color: #6B6666;
                }

                .industry {
                    color: #61BCAC;
                }
            }
        }

    }
}

@media screen and (max-width:760px) {
    .notif {
        &_top {
            &_dp {
                img {

                    width: 128px;
                    height: 128px;
                }
            }

            &_desc {
                text-align: center;
            }
        }

        &_bottom {
            &_card {
                flex-direction: column;
                padding-left: 2vw;
                margin: 5vw 0;

                img {
                    width: 70px;
                    height: 70px;
                }

                &_right {
                    text-align: center;

                    h6 {
                        font-size: 16px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}