@import "../../styles/variables.scss";

.button {
	margin-top: $font_large;
	width: 30%;
	outline: none;
	cursor: pointer;
	color: white;
	font-family: "Nunito", Helvetica, Arial, sans-serif;
	font-size: $font_verysmall;
	font-weight: 600;
	line-height: 1.21;

	padding: $font_small 0;
	border-radius: 34px;
	border: 1px solid transparent;
	background: #61bcac;

	&__disabled {
		box-shadow:
			6px 6px 9px 0 #42597a,
			-1px -1px 4px 0 #42597a;
		background: #42597a;
		border-color: #42597a;
		cursor: not-allowed;
		opacity: 0.5;
		color: white;
	}

	&:active {
		box-shadow:
			inset -6px -6px 6px 0,
			inset 6px 6px 8px 0 #61bcac,
			inset 6px 6px 8px 0 #61bcac,
			inset -6px -6px 6px 0 #61bcac !important;
		border-image-slice: 1;
		background: #61bcac !important;
		background-origin: border-box;
	}

	// &:hover {
	//   color: #61bcac;
	//   border-color: #61bcac;
	//   background-color: #FCFDFF;
	// }
}
