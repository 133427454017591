@import "../../styles/variables.scss";

.profile {
	.ptag {
		margin-top: 1vw;
		margin-left: 1vw;
		width: 100%;
		font-size: 1.1vw;
		color: #696666;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	&_container {
		width: 68%;
		padding: 0 0 4vw 0;
	}
	&_banner {
		width: 100%;
		img {
			width: 100%;
			height: 380px;
			object-fit: cover;
		}
	}
	&_user {
		padding: 0 0 3vw 0;
		&_details {
			padding: 2vw 0;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			&_left {
				display: flex;
				justify-content: center;
			}
			&_image {
				img {
					width: 7vw;
					height: 7vw;
					border-radius: 50%;
					object-fit: fill;
				}
			}
			&_content {
				padding: 0 0 0 2vw;
				&_name {
					h3 {
						font-size: 1.6vw;
						font-weight: 600;
						padding-bottom: 0.4vw;
					}
				}
				&_follow {
					display: flex;
					span {
						font-size: 1.1vw;
						color: #696666;
					}
					p {
						padding: 0 0.5vw;
						font-size: 1.1vw;
						color: #696666;
					}
				}
				&_images {
					padding: 1vw 0 0 0;
					img {
						width: 2.5vw;
						height: 2.5vw;
					}
				}
			}
			&_right {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				padding-top: 1vw;
				&_buttonsec {
					width: 8vw;
					height: 1vw;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 2vw;
					background: #61bcac;
					padding: 0.6vw 0vw;
					margin: 0.3vw;
					cursor: pointer;
					a {
						display: flex;
					}
					img {
						width: 1.2vw;
					}
					p {
						color: white;
						font-size: 0.9vw;
						margin-left: 0.5vw;
					}
				}
			}
		}
		&_data {
			&_sections {
				display: flex;
				justify-content: space-between;
				&_left {
					width: 36%;
					position: -webkit-sticky;
					position: sticky;
					top: 0;
					height: max-content;
					overflow-y: auto;
					&_container {
						display: flex;
						flex-direction: column;
						
					
					}
					&_intro {
						padding: 1vw 1vw 1.5vw 1vw;
						display: flex;
						flex-direction: column;
						align-items: center;
						border-radius: 6px;
						border: 1px solid #ebebeb;
						background: #fff;
						box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
						&_heading {
							width: 99%;
							h4 {
								font-size: 1.4vw;
								font-weight: 400;
							}
						}

						&_content {
							width: 100%;
							padding: 0.5vw 0 0 0;
							p {
								font-size: 0.8vw;
								color: #828282;
								padding: 0.4vw 0;
								font-weight: 500;
								span {
									color: black;
									padding: 0 0.2vw 0 0;
								}
							}
						}
					}
					&_links {
						padding: 1vw 1vw;
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: 1.8vw 0;
						border-radius: 0.3vw;
						border: 1px solid #ebebeb;
						background: #fff;
						box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
						&_heading {
							width: 99%;
							h4 {
								font-size: 1.4vw;
								font-weight: 400;
							}
						}
						&_content {
							width: 100%;
							padding: 0.5vw 0 1vw 0;
							p {
								font-size: 0.8vw;
								color: #828282;
								padding: 0.2vw 0;
								font-weight: 500;
								span {
									color: black;
									padding: 0 0.2vw 0 0;
								}
							}
						}
					}
					&_photos {
						padding: 1vw 0.6vw 1.5vw 0.6vw;
						display: flex;
						flex-direction: column;
						align-items: center;
						border-radius: 0.3vw;
						border: 1px solid #ebebeb;
						background: #fff;
						box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
						&_heading {
							width: 100%;
							display: flex;
							justify-content: space-between;
							align-items: flex-end;

							h4 {
								font-size: 1.4vw;
								font-weight: 400;
							}
							span {
								font-size: 0.8vw;
								color: #958585;
								border-bottom: 1px solid #958585;
							}
						}
						&_container {
							width: 100%;
							display: flex;
							flex-wrap: wrap;
							padding: 0.6vw 0 0 0;

							img {
								width: calc(25% - 0.5vw);
								margin: 0.6vw 0.5vw 0 0;
							}

							img:nth-child(4n) {
								margin: 0.6vw 0 0 0;
							}
						}
					}
					&_friend {
						margin: 1.8vw 0;
						padding: 1vw 0.6vw;
						display: flex;
						flex-direction: column;
						align-items: center;
						border-radius: 6px;
						border: 1px solid #ebebeb;
						background: #fff;
						box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
						&_heading {
							width: 100%;
							display: flex;
							justify-content: space-between;
							align-items: flex-end;

							h4 {
								font-size: 1.4vw;
								font-weight: 400;
							}
							span {
								font-size: 0.8vw;
								color: #958585;
								border-bottom: 1px solid #958585;
							}
						}
						&_container {
							width: 100%;
							display: flex;
							flex-wrap: wrap;
							padding: 0.6vw 0 0 0;
							img {
								width: 5vw;
								margin: 0.6vw 0.6vw 0 0;
								border-radius: 0.8vw;
								height: 5vw;
							}

							img:nth-child(4n) {
								width: 5vw;
								margin: 0.6vw 0 0 0;
								border-radius: 0.8vw;
								height: 5vw;
							}
						}
					}
				}
				::-webkit-scrollbar {
					display: none;
				}

				.sticky {
					position: sticky;
					top: 0;
				}
				&_right {
					width: 60%;
					&_tabsection {
						.tablist-custom {
							display: flex;
							justify-content: space-between;
							padding: 0.5vw 1vw 0 1vw;
							// margin: 1.5vw 0 0;
							border-radius: 6px;
							border: 1px solid #ebebeb;
							background: #fff;
							box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
							

							.tabimage {
								width: 12vw;
								text-align: end;

								.react-tabs__tab {
									padding-top: 0;
									margin-right: 0;
								}
								img {
									width: 90%;
								}
							}
						}

						.react-tabs__tab-list {
							border-radius: 0.3vw;
							box-shadow: 0 0 4px rgba(0, 0, 0, 0.2784313725);
						}

						.react-tabs__tab {
							font-size: 1.4vw;
							font-style: normal;
							font-weight: 400;
							margin-right: 1.5vw;
						}

						.react-tabs__tab--selected {
							border: none !important;
							border-bottom: 0.4vw solid #61bcac !important;
							padding-bottom: 1.3vw;
						}
					}
					&_container {
						display: flex;
						flex-direction: column;
					}
					&_filter {
						margin-bottom: 1vw;
						display: flex;
						justify-content: space-between;
						align-items: center;
						background: #f9f9f9;
						padding: 0.8vw 1vw;
						border-radius: 0.3vw;
						border-radius: 6px;
						background: #fff;
						box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
						h4 {
							font-size: 1.4vw;
							font-weight: 400;
						}
						img {
							width: 9vw;
							margin-right: 1vw;
						}
					}
				}
			}
		}
	}
}

.popup {
	color: #6c6c6c;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 44%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 0.5vw;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	z-index: 999;

	&_top {
		border-radius: 10px;
		border: 1px solid #d1c3c3;
		padding: 0rem 0.5rem 2rem;
		&_rating {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.8vw 0;
			.mobilepostbutton {
				display: none;
			}
			.MuiRating-root {
				font-size: 1vw;
			}
		}
		&_content {
			display: flex;
			justify-content: space-between;
			&_text {
				width: 80%;
				font-size: 0.9vw;
			}
			&_postbuttons {
				display: flex;
				flex-direction: column-reverse;
				align-items: flex-end;
				justify-content: flex-end;
			}
			&_postbutton {
				margin: 0.3vw;
				font-size: 0.9vw;
				height: 1.2vw;
				width: 7vw;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 2vw;
				background: #61bcac;
				color: white;
				padding: 0.5vw 0.2vw;
				cursor: pointer;
			}
		}
	}
	.loader {
		display: inline-block;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		-webkit-animation: spin 3s linear infinite;
		animation: spin 3s linear infinite;
	}
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	&_bottom {
		display: flex;
		justify-content: space-between;
		margin: 1vw 0;
		&_type {
			width: 86%;
		}
		.hemant {
			padding: 0.5vw 0.8vw 0.5vw 0.8vw;
			width: 96%;
			background: #fff;
			font-family: Inter;
			font-size: 0.9vw;
			border-radius: 30px;
			border: 1px solid #ccc;
			color: #5a5757;
			outline: none;
			resize: none;
			display: flex;
			align-items: center;
		}
		.count {
			width: 100%;
			display: flex;
			font-weight: 500;
			justify-content: flex-end;
			font-size: 0.8vw;
			color: #5a5757;
		}
		&_image {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 12%;
			height: 12%;
			padding: 0.5vw 0;
			border-radius: 2vw;
			background: #61bcac;
			cursor: pointer;
			img {
				width: 30%;
				height: 30%;
			}
		}
	}
}
.popupclose {
	height: 2vw;
	position: fixed;
	top: -2.5vw;
	right: -5px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	img {
		cursor: pointer;
		border-radius: 50%;
		background-color: #d9d9d9;
	}
}
.blurred-background {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.9);
	backdrop-filter: blur(5px);
	z-index: 998;
}
.modalbutton {
	background: #61bcac;
	color: #fff;
	padding: 0.5vw;
	font-size: 1vw;
	border: none;
	width: 35%;
	cursor: pointer;
	border-radius: 1vw;
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.profile {
		.ptag {
			font-size: 3.4vw;
		}
		&_container {
			width: 100%;
			padding: 0;
		}
		&_banner {
			img {
				max-height: 157px;
				object-fit: cover;
				object-position: top;
			}
		}
		&_user {
			padding: 8vw 18px;
			&_details {
				padding-block: 0 4vw;
				flex-direction: column;
				&_left {
					flex-direction: column;
					align-items: center;
					width: 100%;
				}
				&_right {
					width: 100%;
					flex-wrap: wrap;
					margin-top: 3vw;
					row-gap: 14px;
					column-gap: 8px;
					&_buttonsec {
						flex: 48%;
						border-radius: 50px;
						padding: 4vw 0;
						margin: 0;
						img {
							width: 19px;
							height: 19px;
						}
						p {
							font-size: 16px;
							margin-left: 2vw;
						}
					}
				}
				&_image {
					img {
						width: 200px;
						height: 210px;
					}
				}
				&_content {
					padding: 4vw 0 0 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					&_name {
						h3 {
							color: #000;
							font-family: Inter;
							font-size: 36px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
					}
					&_follow {
						padding: 2vw 0;
						span {
							font-size: 16px;
						}
						p {
							font-size: 19px;
							padding: 0 6px;
						}
					}
					&_images {
						img {
							width: 46px;
							height: 46px;
						}
					}
				}
			}
			&_data {
				&_sections {
					flex-direction: column;
					&_left {
						width: 100%;
						&.sticky {
							position: static;
						}
						&_intro {
							padding: 6vw 15px;
							&_heading {
								padding: 0 0 0 8px;
								h4 {
									font-size: 26px;
								}
							}
							&_button {
								padding: 12px 0;
								button {
									padding: 8px 0;
									font-size: 15px;
									font-weight: 500;
									border: none;
								}
							}
							&_content {
								width: 90%;
								padding: 6px 0;
								p {
									font-size: 15px;
									padding: 6px 0;
								}
							}
						}
						&_links {
							padding: 12px 0;
							&_heading {
								align-items: center;
								width: calc(100% - 24px);
								padding: 0 8px 8px 16px;

								h4 {
									font-size: 26px;
								}

								span {
									font-size: 14px;
								}
							}
							&_content {
								width: calc(100% - 16px);
								padding: 0 0 0 16px;
								p {
									font-size: 14px;
									padding: 0 0 8px 0;
								}
							}
						}
						&_photos {
							margin: 18px 0 12px 0;
							padding: 6vw 15px;
							&_heading {
								align-items: center;
								padding: 0 0 0 8px;
								h4 {
									font-size: 26px;
								}
								span {
									font-size: 14px;
								}
							}
							&_container {
								padding: 4vw 0 0 0;
								display: grid;
								grid-template-columns: repeat(3, 1fr);
								grid-gap: 10px;
								img {
									grid-area: span 1;
									margin: 0;
									width: 100%;
									aspect-ratio: 1;
								}
							}
						}
						&_friend {
							margin: 18px 0 12px 0;
							padding: 6vw 15px;
							&_heading {
								align-items: center;
								padding: 0 0 0 8px;
								h4 {
									font-size: 26px;
								}
								span {
									font-size: 14px;
								}
							}
							&_container {
								padding: 4vw 0 0 0;
								display: grid;
								grid-template-columns: repeat(3, 1fr);
								grid-gap: 10px;
								img {
									grid-area: span 1;
									margin: 0;
									width: 100%;
									aspect-ratio: 1;
									height: auto;
								}
								img:nth-child(4n) {
									width: 100%;
									margin: 0;
									height: auto;
								}
							}
						}
					}
					&_right {
						width: 100%;

						&_tabsection {
							.tablist-custom {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								.tabimage {
									width: 5vw;

									img {
										width: 80px;
										height: 28px;
									}
								}
							}

							.react-tabs__tab {
								font-size: 15px;
							}

							.react-tabs__tab--selected {
								border: none !important;
								border-bottom: 1vw solid #61bcac !important;
								padding-bottom: 15px;
							}
						}

						&_filter {
							margin: 6px 0;
							padding: 4vw 15px;

							h4 {
								font-size: 18px;
							}

							img {
								width: 100px;
							}
						}
					}
				}
			}
		}
	}
	.popup {
		width: 88dvw;
		padding: 32px 14px;
		&_top {
			border: 0;
			padding-inline: 0.5rem;
			&_rating {
				.mobilepostbutton {
					width: 30%;
					font-weight: 300;
					display: flex;
					font-size: 16px;
					padding: 3vw 0.5vw;
					border-radius: 30px;
				}
			}
			&_content {
				&_text {
					width: 100%;
					margin-top: 1vw;
					font-size: 16px;
					text-wrap: wrap;
				}
				&_postbutton {
					&.cancel {
						display: none;
					}
					display: none;
				}
			}
		}
		&_bottom {
			position: relative;
			margin: 0;
			&_type {
				width: auto;
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				position: relative;
			}
			.hemant {
				padding: 14px 22px;
				width: auto;
				background: #fff;
				font-family: Inter;
				font-size: 3vw;
				border-radius: 30px;
				border: 1px solid #ccc;
				color: #5a5757;
				outline: none;
				resize: none;
				display: flex;
				align-items: center;
			}
			.count {
				display: none;
			}
			&_image {
				width: 15%;
				border-radius: 30px;
				position: absolute;
				padding: 0;
				right: 0;
				bottom: 0;
				top: 0;
				height: 100%;
			}
		}
	}
	.popupclose {
		width: 7vw;
		height: 7vw;
		top: -9vw;
		right: 1.5vw;
	}
}
