.post {
  margin: 0.8vw 0 0.8vw 0;
  padding: 0.5vw;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

  &_comment {
    margin: 0.5vw 0 0;
    .allComments {
      max-height: 10vw;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .view {
      font-weight: 500;
      font-size: 1vw;
      color: #6a6a6a;
      cursor: pointer;
      img {
        margin-left: 0.3vw;
        opacity: 0.5;
      }
    }
    .view:hover {
      text-decoration: underline;
    }
    &_data {
      margin: 0.5vw 0;
      display: flex;
      align-items: center;
      &_left {
        img {
          width: 2.7vw;
          height: 2.7vw;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      &_right {
        padding: 0 0.5vw;
       
        &_comment {
          p {
            font-size: 0.9vw;
            color: #6a6a6a;
          }
          span{
            font-size: 0.9vw;
            color: #6a6a6a;
          }
        }
        &_name {
          p {
            font-size: 0.9vw;
            font-weight: 600;
             color:#256e61;
          }
        }
      }
    }
    &_section {
      margin: 0.5vw 0;
      display: flex;
      &_left {
        width: 10%;
        img {
          width: 2.7vw;
          height: 2.7vw;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      &_right {
        width: 90%;
        background: #f2f2f2;
        padding: 0.5vw;
        border-radius: 0.5vw;
        &_content {
          .hemant {
            width: 100%;
            background: #f2f2f2;
            font-family: Inter;
            font-size: 1vw;
            font-weight: 500;
            border: none;
            color: #5a5757;
            outline: none;
            resize: none;
            display: flex;
            align-items: center;
           
          }
          .count{
            width: 100%;
            display: flex;
            font-weight: 500;
            justify-content: flex-end;
            font-size: 0.8vw;
            color: #5a5757;
          }
        }
        &_likes {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 0.5vw;
          img {
            width: 1.5vw;
            cursor: pointer;
          }
        }
      }
    }
  }

  &_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_left {
      display: flex;
      align-items: center;

      img {
        width: 2.7vw;
        height: 2.7vw;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
      }

      &_user {
        padding: 0 0 0 1vw;

        &_name {
          h6 {
            font-size: 0.9vw;
            color:#256e61;
          }
        }

        &_date {
          display: flex;
          align-items: center;
          padding: 0.2vw 0 0 0;

          p {
            font-size: 0.8vw;
            color: #61bcac;
            padding: 0 0.6vw 0 0;
          }
          span {
            font-size: 0.7vw;
            color: #787878;
          }
        }
      }
    }

    &_right {
      img {
        width: 1vw;
        height: 1vw;
        margin-right: 0.3vw;
      }
    }
  }

  &_comments {
    display: flex;
    flex-direction: column;
    padding: 0 0 0.5vw 0;

    &_image {
      img {
        padding: 0 0 0.6vw 0;
        width: 100%;
        // height: 485px;
        object-fit: contain;
      }
    }

    &_current {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5vw 0;
      &_count {
        display: flex;
        align-items: center;

        img {
          width: 1.4vw;
          height: 1.4vw;
          padding: 0;
        }

        p {
          padding: 0 0 0 0.5vw;
          font-size: 0.9vw;
          color: #6a6a6a;
        }
      }

      p {
        font-size: 0.9vw;
        color: #6a6a6a;
      }
    }
  }

  &_content {
    margin: 0.5vw 0;
    p {
      font-size: 0.9vw;
      color: #6a6a6a;
    }
  }

  &_bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    padding: 1vw 0;
    &_like {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 1.4vw;
        height: 1.4vw;
        padding: 0;
      }
      .activelike {
        color: #38c9ff;
        padding: 0 0 0 0.5vw;
        font-size: 0.9vw;
      }
      .inactivelike {
        padding: 0 0 0 0.5vw;
        font-size: 0.9vw;
        color: #6a6a6a;
      }
    }

    &_comment {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 1.4vw;
        height: 1.4vw;
        padding: 0;
      }

      p {
        padding: 0 0 0 0.5vw;
        font-size: 0.9vw;
        color: #6a6a6a;
      }
    }

    &_share {
      display: flex;
      align-items: center;

      img {
        width: 1.4vw;
        height: 1.4vw;
        padding: 0;
      }

      p {
        padding: 0 0 0 0.5vw;
        font-size: 0.9vw;
        color: #6a6a6a;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .post {
    padding: 0;
    margin: 18px 0;
    border: none;

    &_mobile {
      border-radius: 6px;
      // border: 1px solid #E8E6E6;
      background: #fff;
      // box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
      margin-bottom: 2px;
    }

    &_heading {
      padding: 5px 15px 0 15px;

      &_left {
        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }

        &_user {
          &_name {
            h6 {
              font-size: 15px;
              padding-bottom: 4px;
            }
          }

          &_date {
            p {
              font-size: 12px;
              padding-right: 10px;
            }

            span {
              font-size: 11px;
            }
          }
        }
      }

      &_right {
        img {
          width: 10px;
          height: 10px;
        }
      }
    }

    &_content {
      p {
        font-size: 15px;
        padding: 2vw 3vw 0;
      }
    }

    &_comments {
      padding: 0;

      &_image {
        padding: 0 8px;
        height: auto;

        img {
          // height: 180px;
          object-fit: contain;
          border-radius: 5px;
					padding: 0;
        }
      }

      &_current {
        background: white;
        padding: 0px 10px;

        &_count {
          img {
            width: 18px;
            height: 18px;
          }

          p {
            display: block !important;
            font-size: 17px;
          }
        }

        p {
          display: none;
        }
      }
    }

    &_bottom {
      background: white;
      border: none;
      padding: 10px 15px 10px 15px;
      border-radius: 6px;
      cursor: pointer;
      &_like {
        border-radius: 40px;
        background: #f2f2f2;
        padding: 4px 10px;
        img {
          width: 16px;
          height: 16px;
        }
        .activelike {
          color: #38c9ff;
          font-size: 14px;
        }
        .inactivelike {
          font-size: 14px;
        }
      }

      &_comment {
        background: #f9f9f9;
        padding: 4px 10px;
        border-radius: 10px;

        img {
          width: 16px;
          height: 16px;
        }

        p {
          font-size: 14px;
        }
      }

      &_share {
        background: #f9f9f9;
        padding: 4px 10px;
        border-radius: 10px;

        img {
          width: 16px;
          height: 16px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    &_comment {
      padding: 0 3vw 2vw;
      .allComments {
        max-height: 25vw;
      }
      .view {
        font-weight: 300;
        font-size: 4vw;
        color: #6a6a6a;
        cursor: pointer;
        img {
          margin-left: 0.3vw;
          opacity: 0.5;
        }
      }
      &_data {
        margin: 2vw 0;
        &_left {
          img {
            width:9vw;
            height:9vw;
          }
        }
        &_right {
          padding: 0 3vw;

          &_comment {
            p {
              font-size: 3.3vw;
            }
            span{
              font-size: 3.3vw;
            }
          }
          &_name {
            p {
              font-size: 3.5vw;
            }
          }
        }
      }
      &_section {
        margin: 0.5vw 0;
        display: flex;
        &_left {
          width: 15%;
          img {
            width:9vw;
            height:9vw;
          }
        }
        &_right {
          width: 85%;
          border-radius: 2vw;
          &_content {
            .hemant {
              width: 98%;
              font-size: 3.5vw;
              
              
            }
          }
          &_likes {
            margin-top: 1vw;
            img {
              width: 4.5vw;
            }
          }
        }
      }
    }
  }
}
