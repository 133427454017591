.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
	padding: 0.5vw 0.5vw 0.5vw 1.2vw;
}

.engineeringsignup2 {
	background: #ebebeb;
	&_container {
		display: flex;
		justify-content: center;
		padding: 5vw 2vw 2vw 2vw;
		&_content {
			border: 1px solid #d6cece;
			background: #fff;
			width: 80%;
			border-radius: 0.5vw;
			&_top {
				padding: 1.8vw;
				background: #61bcac;
				border-start-end-radius: 0.5vw;
				border-start-start-radius: 0.5vw;
				p {
					font-size: 1.6vw;
					color: #fff;
					margin-left: 2vw;
				}
			}
			&_bottom {
				padding: 2vw 2vw 0 3vw;
				&_top {
					display: flex;
					align-items: center;
					margin-bottom: 1.5vw;
					p {
						color: #838383;
						margin-right: 2vw;
					}
				}
				&_mec {
					display: flex;
					margin-bottom: 1.5vw;
					p {
						margin-left: 1vw;
						font-size: 1.2vw;
						font-weight: 500;
					}
					&_data {
						margin-left: 2.5vw;
						display: flex;
						width: 90%;
						margin-bottom: 1.5vw;
						justify-content: space-between;
						&_types {
							display: flex;
							p {
								color: #727272;
								margin-left: 1vw;
								font-size: 1vw;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}
	&_button {
		display: flex;
		justify-content: center;
		padding-bottom: 4vw;
		&_content {
			width: 77%;
			display: flex;
			justify-content: flex-end;
			button {
				padding: 1vw;
				background: #61bcac;
				color: #fff;
				font-size: 2vw;
				width: 25%;
				border: none;
				border-radius: 10vw;
			}
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.engineeringsignup2 {
		.MuiOutlinedInput-root {
			padding: 2vw 4vw;
		}
		background: none;
		&_container {
			padding: 0;
			margin: 7vw 6vw;
			&_content {
				width: 100%;
				border: none;
				&_top {
					padding: 4vw 2vw;
					border-radius: 3vw;
					margin-bottom: 5vw;
					p {
						font-size: 7.5vw;
						text-align: center;
						margin: 0;
					}
				}
				&_bottom {
					&_top {
						margin-bottom: 5vw;
						p {
							font-size: 4.5vw;
							margin-right: 4vw;
						}
					}
					&_mec {
						margin-bottom: 5vw;
						p {
							font-size: 5vw;
							margin-left: 3vw;
							font-weight: 500;
						}
						&_data {
							display: block;
							margin-left: 6vw;
							margin-bottom: 5vw;
							&_types {
								margin-bottom: 2vw;
								p {
									font-size: 4vw;
									font-weight: 500;
									margin-left: 3vw;
								}
							}
						}
					}
				}
			}
		}
		&_button {
			margin: 7vw 6vw;
			&_content {
				width: 100%;
				button {
					width: 100%;
					font-size: 5vw;
					padding: 4vw 2vw;
				}
			}
		}
	}
}
