.svsubheader {
	&_header {
		width: 100%;
		background: #61bcac;
		display: flex;
		align-items: center;
		justify-content: center;

		&_main {
			width: 92%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.5vw 0 0;

			&_left {
				display: flex;
				justify-content: space-between;
				align-items: center;

				&_search {
					width: 25%;
					// margin: 0.5vw 0;
					padding-bottom: 0.5vw;
				}

				&_tabsection {
					ul {
						display: flex;

						li {
							padding: 0.5vw 1vw 0;
							color: white;
							transition:
								border 0.3s,
								color 0.3s; // Add transition for smooth effect

							a {
								display: flex;
								color: white;
								text-decoration: none; // Remove the default underline
								font-weight: 300;

								// &:visited {
								//     border: 2px solid black;
								//     color: blue;
								// }
							}

							&.active {
								// border: 2px solid black;
								// color: red;

								a {
									padding-bottom: 10px;
									border-bottom: 5px solid #2d7d6f;
									font-weight: 600;
									color: white; // Optional: Set the color for the link when active
								}
							}
						}
					}
				}

				img {
					width: 20vw;
				}
			}

			&_center {
				display: flex;
				align-items: center;
				justify-content: center;

				ul {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				li {
					border-radius: 70px;
					background: #256f61;
					padding: 0.2vw 1vw;
					margin: 0.3vw 0.6vw;
					color: white;

					a {
						display: flex;
						color: white;
						font-size: 0.8vw;
						padding: 0.5vw 0;

						img {
							width: 1vw;
							margin: 0vw 0.3vw;
						}
					}
				}
			}

			&_right {
				cursor: pointer;
				padding-bottom: 0.8vw;
				p {
					color: white;
					border-radius: 5px;
					background: #256f61;
					padding: 0.3vw 0.8vw;
				}
				img {
					display: none;
				}
			}

			&_rightmob {
				// display: none;
				display: flex;
				justify-content: space-between;
				align-items: center;

				&_container {
					display: none;
				}

				ul {
					display: flex;
				}

				li {
					padding: 0.5vw 1.5vw;

					a {
						color: white;
					}
				}
			}
		}
	}
}

// Responsive---Mobile
@media screen and (max-width: 761px) {
	.svsubheader {
		&_header {
			&_main {
				width: 100%;
				display: flex;
				flex-direction: column-reverse;
				background: white;

				&_left {
					width: 98%;
					justify-content: space-between;

					&_search {
						display: none;
					}

					&_tabsection {
						width: 100%;

						ul {
							// width: 100%;
							justify-content: space-between;

							li {
								a {
									color: #858585;
									font-weight: 500;
								}

								&.active {
									a {
										color: #000;
										border-bottom: 2px solid #2d7d6f;
									}
								}
							}

							li:last-child {
								display: none;
							}
						}
					}
				}

				&_center {
					width: 100%;
					padding-bottom: 3.5vw;
					// padding-top: 3.5vw;

					ul {
						width: 100%;
					}

					li {
						width: 50%;

						a {
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 16px;
							padding: 1.5vw 0;

							img {
								width: 6vw;
								margin-right: 2.5vw;
							}
						}
					}
				}

				&_right {
					width: 95%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 4vw 0vw;

					p {
						padding: 2vw;
					}

					img {
						display: block;
						width: 25px;
						height: 18px;
					}

					&_rightmob {
						display: flex !important;
						justify-content: space-between;
						align-items: center;
						width: 20%;
						display: block;
					}
				}
			}
		}
	}

	/*svsubheader-closed */
}
