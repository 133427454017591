.engineerhome {
	display: flex;
	flex-direction: column;
	margin-bottom: 5vw;

	&_header {
		width: 100%;
		background: #61bcac;
		display: flex;
		align-items: center;
		justify-content: center;

		&_main {
			width: 95%;
			display: flex;
			justify-content: space-between;

			&_left {
				display: flex;
				justify-content: space-between;
				align-items: center;

				ul {
					display: flex;

					li {
						padding: 0.5vw 2vw;
						color: white;

						a {
							display: flex;
							color: white;
						}
					}
				}
			}

			&_center {
				display: flex;
				align-items: center;
				justify-content: center;

				ul {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				li {
					border-radius: 30px;
					background: #256f61;
					padding: 0.4vw 0.5vw;
					margin: 0.3vw 1.2vw;
					color: white;

					a {
						display: flex;
						color: white;
						font-size: 0.8vw;
						padding: 0.5vw 0;

						img {
							width: 1vw;
							margin: 0vw 0.3vw;
						}
					}
				}
			}

			&_right {
				display: flex;
				justify-content: space-between;
				align-items: center;

				&_rightmob {
					display: none;
				}

				ul {
					display: flex;
				}

				li {
					padding: 0.5vw 0.5vw;

					a {
						color: white;
					}
				}
			}
		}
	}

	&_mini {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&_mini1 {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	&_heading {
		margin-top: 2vw;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 95%;

		&_main {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-right: 1vw;

			&_left {
				display: flex;
				justify-content: space-between;
				color: #61bcac;
				align-items: center;

				p {
					margin-left: 1vw;
					color: #000;
					font-size: 2.2vw;

					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}

				&_one {
					span {
						margin: 0 3vw;
						color: #61bcac;
						//   font-family: Poppins;
						font-size: 2vw;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
				}
			}

			&_right {
				button {
					color: white;
					border-radius: 50px;
					background: #3abf81;
					padding: 1vw 1.5vw;
					border: none;
				}
			}
		}
	}

	&_cards {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 95%;
		margin-top: 2vw;

		&_main {
			width: 21%;
			border-radius: 6px;
			background: #fff;
			box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.25);
			padding: 1vw;

			&_first {
				margin: 2vw 0.4vw;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.firstone {
					display: flex;

					img {
						width: 20%;
						margin-right: 1vw;
					}

					h2 {
						font-size: 1.3vw;
					}

					p {
						font-size: 1vw;
					}
				}
			}

			&_second {
				margin: 0.8vw;

				h2 {
					font-size: 1.2vw;
					font-weight: 600;
					margin-bottom: 1vw;
				}

				.doller {
					display: flex;
					align-items: center;

					span {
						border: 1px solid #ada7a7;
						border-radius: 50px;
						height: 1.7vw;
						width: 1.7vw;
						text-align: center;
						color: #929292;
						margin-right: 0.5vw;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					p {
						color: #929292;
					}
				}
			}

			&_third {
				button {
					border-radius: 6px;
					background: #f1f1f1;
					border: none;
					font-size: 0.8vw;
					height: 2vw;
					margin: 0.3vw;
				}
			}

			&_four {
				display: flex;
				margin: 1vw 0vw;

				button {
					width: 100%;
					border-radius: 40px;
					border: 2px solid #61bcac;
					background: white;
					padding: 0.8vw 0;

					span {
						font-size: 1.3vw;
						font-weight: 500;
					}
				}
			}
		}
	}
}

// Responsive---Mobile
@media screen and (max-width: 761px) {
	.engineerhome {
		&_header {
			&_main {
				width: 95%;
				display: flex;
				flex-direction: column-reverse;

				&_left {
					display: none;
				}

				&_center {
					padding-bottom: 3.5vw;

					ul {
						width: 100%;
					}

					li {
						width: 50%;

						a {
							display: flex;
							justify-content: center;
							font-size: 16px;
							padding: 2.5vw 0;

							img {
								width: 6vw;
								margin-right: 2.5vw;
							}
						}
					}
				}

				&_right {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 4vw 0vw;
					&_rightmob {
						display: flex !important;
						justify-content: space-between;
						align-items: center;
						width: 25%;
						display: block;
					}
				}
			}
		}

		&_mini {
			display: flex;
		}

		&_heading {
			margin-top: 2vw;
			width: 95%;
			display: flex;

			&_main {
				display: flex;
				flex-direction: column-reverse;

				&_left {
					width: 100%;
					display: flex;
					align-items: flex-start;
					flex-direction: column;

					p {
						font-size: 27px;
					}

					&_one {
						display: flex;

						span {
							text-align: left;
							font-size: 24px;
						}
					}
				}

				&_right {
					width: 100%;
					display: flex;
					justify-content: flex-end;
					align-items: flex-end;
					padding: 5vw 0vw;
					button {
						font-size: 21px;
						padding: 2.5vw 8vw;
					}
				}
			}
		}

		&_mini1 {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}

		&_cards {
			flex-direction: column;

			&_main {
				width: 95%;
				padding: 2vw;
				margin: 4vw 0vw;

				&_first {
					align-items: flex-start;
					.firstone {
						img {
							width: 25%;
							margin-right: 6vw;
						}

						h2 {
							font-size: 19px;
						}

						p {
							font-size: 12px;
						}
					}
				}

				&_second {
					margin: 0.8vw;

					h2 {
						font-size: 22px;
						font-weight: 600;
						margin: 4vw 0vw;
					}

					.doller {
						span {
							height: 7.7vw;
							width: 7.7vw;
							font-size: 22px;
						}

						p {
							font-size: 22px;
						}
					}
				}

				&_third {
					button {
						font-size: 10px;
						height: 9vw;
						margin: 2vw;
						padding: 3.2vw;
					}
				}

				&_four {
					margin: 2vw 4vw;
					button {
						padding: 3vw 0;

						span {
							font-size: 18px;
						}
					}
				}
			}
		}
	}

	/*engghome-closed */
}
