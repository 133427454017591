.mbheading {
    background: #f5f5f5;

    &_container {
        display: flex;
        justify-content: center;
    }

    &_sections {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_left {
        display: flex;
        align-items: center;

        .img {
            margin: 1.2vw 0;
            width: 9vw;
            border-right: 1px solid #cbc3c3;
            padding-right: 1vw;
            cursor: pointer;
        }

        &_content {
            display: flex;
            margin: 1.2vw 0;
            align-items: center;

            .image {
                display: flex;
                align-items: center;
                width: 1.5vw;
                height: 1.5vw;
                padding-left: 1vw;
                padding-right: 1vw;
            }

            a {
                cursor: pointer;
            }

            h3 {
                font-size: 1.2vw;
                color: #256e61;
            }
        }
    }

    &_right {
        width: 30%;
    }

    &_mobile {
        display: none;
    }

    &_middle {
        display: flex;
        justify-content: space-between;
        width: 18%;

        .upload {
            border: 1px solid #bebebe;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.2vw 1.5vw;
            background: #fff;
            border-radius: 1vw;
            font-size: 0.9vw;
            font-weight: 500;
        }

        &_content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 45%;

            img {
                width: 1.5vw;
                height: 1.5vw;
            }
        }
    }

    &_user {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            display: flex;
            align-items: center;
        }

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        li {
            color: #605656;
            margin-left: 2vw;

        }

        .username {
            display: flex;
            align-items: center;
            // margin-left: 2vw;
            position: relative;
            cursor: pointer;

            p {
                font-size: 1vw;
                margin-left: 0.5vw;
                padding-top: 0.2vw;
                cursor: pointer;
            }

            .dropdown-content {
                margin-top: 2vw;
                box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
                background-color: #FFF;
                position: absolute;
                top: 1.2vw;
                right: 0;
                width: 25vw;
                padding-bottom: 1vw;

                // .dropdown-items {
                //     .dropdown-heading {
                //         display: flex;
                //         padding-top: 1vw;

                //         img {
                //             width: 0.5vw;
                //             height: 0.4vw;
                //             margin: 0.5vw 1vw 0 1vw;
                //         }

                //         h3 {
                //             font-size: 1vw;
                //             color: #30302F;
                //             font-weight: 500;
                //         }
                //     }

                //     ul {
                //         display: flex;
                //         justify-content: flex-start;

                //         .dropdown-left {
                //             display: flex;
                //             flex-direction: column;
                //             align-items: start;

                //             li {
                //                 .dropdown-list {
                //                     margin: 1vw 0 0 2vw;

                //                     .dropdown-img {
                //                         display: flex;

                //                         img {
                //                             width: 1vw;
                //                             height: 1vw;
                //                             margin-right: 1vw;
                //                         }

                //                         h5 {
                //                             font-size: 0.8vw;
                //                             color: #696A68;
                //                             font-weight: 400;
                //                         }
                //                     }

                //                     .dropdown-user {
                //                         display: flex;
                //                         margin: 0.5vw 0 0 1.5vw;

                //                         h6 {
                //                             font-size: 0.6vw;
                //                             font-weight: 400;
                //                             color: #000;
                //                             margin-top: 0.2vw;
                //                         }

                //                         input {
                //                             width: 0.6vw;
                //                             height: 0.6vw;
                //                             margin-right: 0.5vw;
                //                         }
                //                     }
                //                 }
                //             }
                //         }

                //         .dropdown-right {
                //             display: flex;
                //             flex-direction: column;
                //             padding-right: 1vw;

                //             li {
                //                 .dropdown-list {
                //                     margin: 1vw 0 0 2vw;

                //                     .dropdown-img {
                //                         display: flex;

                //                         img {
                //                             width: 1vw;
                //                             height: 1vw;
                //                             margin-right: 1vw;
                //                         }

                //                         h5 {
                //                             font-size: 0.8vw;
                //                             color: #696A68;
                //                             font-weight: 400;
                //                         }
                //                     }

                //                     .dropdown-user {
                //                         display: flex;
                //                         margin: 0.5vw 0 0 1.5vw;

                //                         h6 {
                //                             font-size: 0.6vw;
                //                             font-weight: 400;
                //                             color: #000000;
                //                             margin-top: 0.2vw;
                //                         }

                //                         input {
                //                             width: 0.6vw;
                //                             height: 0.6vw;
                //                             margin-right: 0.5vw;
                //                         }
                //                     }
                //                 }
                //             }
                //         }

                //     }
                // }
            }
        }

    }

    &_logo {
        display: none;
        background: #fcfcfc;

        &_left {
            display: flex;
            align-items: center;
            padding: 1vw;

            .image {
                display: flex;
                align-items: center;
                width: 1.5vw;
                height: 1.5vw;
                padding-left: 1vw;
                padding-right: 1vw;
                margin-right: 1vw;
            }

            h3 {
                color: #256e61;
            }
        }

        &_right {}
    }
}

@media screen and (min-width: 320px) and (max-width: 760px) {
    .mbheading {
        &_container {
            display: flex;
            justify-content: center;
        }

        &_sections {
            width: 95%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_left {
            display: flex;
            width: 52%;
            margin: 3vw 0 3vw;

            .img {
                margin: 0;
                width: 100%;
                border: none;
            }

            &_content {
                display: none;
            }
        }

        &_right {
            display: none;
            width: 30%;
        }

        &_mobile {
            display: flex;

            ul {
                display: flex;
                justify-content: center;
                align-items: center;

            }

            a {
                color: #605656;
                margin-left: 2vw;
            }

            p {
                margin-left: 0.5vw;
                font-family: Inter;
                font-size: 18px;
            }
        }

        &_middle {
            display: flex;
            justify-content: space-between;
            width: 18%;

            .upload {
                border: 1px solid #bebebe;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.2vw 1.5vw;
                background: #fff;
                border-radius: 1vw;
                font-size: 0.9vw;
                font-weight: 500;
            }

            &_content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 45%;

                img {
                    width: 1.5vw;
                    height: 1.5vw;
                }
            }
        }

        &_user {
            display: none;
        }

        &_logo {
            display: block;
            background-color: #D2EAE6;

            &_container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &_left {
                .image {
                    display: flex;
                    align-items: center;
                    width: 8vw;
                    height: 8vw;
                    margin: 3vw 0 3vw 6vw;
                }

                h3 {
                    color: #256e61;
                    margin-left: 3vw;
                    font-size: 23px;
                }
            }

            &_right {
                img {
                    display: flex;
                    align-items: center;
                    width: 6vw;
                    height: 6vw;
                    margin: 3vw 6vw 3vw 0;
                }
            }
        }
    }
}