.privatefriend {
	display: flex;
	flex-direction: column;
	align-items: center;
	&_container {
		width: 68%;
		padding: 0 0 4vw 0;
	}
	&_banner {
		width: 100%;
		img {
			width: 100%;
			height: 380px;
			object-fit: cover;
		}
	}
	&_user {
		padding: 0 0 3vw 0;
		&_details {
			padding: 2vw 0;
			display: flex;
			align-items: center;
			img {
				width: 7vw;
				height: 7vw;
				border-radius: 50%;
			}
			&_content {
				padding: 0 0 0 2vw;
				&_name {
					h3 {
						font-size: 1.6vw;
						font-weight: 600;
						padding-bottom: 0.4vw;
					}
				}
				&_follow {
					display: flex;
					span {
						font-size: 1.1vw;
						color: #696666;
					}
					p {
						padding: 0 0.5vw;
						font-size: 1.1vw;
						color: #696666;
					}
				}
				&_images {
					padding: 1vw 0 0 0;
					img {
						width: 2.5vw;
						height: 2.5vw;
					}
				}
			}
		}
		&_data {
			&_sections {
				display: flex;
				justify-content: center;
				&_right {
					width: 100%;
					&_tabsection {
						box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
						.tablist-custom {
							display: flex;
							justify-content: space-between;
							padding: 0.5vw 1vw 0 1vw;
							margin: 1.5vw 0 0;
							border-radius: 6px;
							border-bottom: 1px solid #ebebeb;
							background: #fff;
							// .tabimage {
							//   width: 12vw;

							//   img {
							//     width: 100%;
							//   }
							// }
						}
						.react-tabs__tab-list {
							border-radius: 0.3vw;
							border-bottom: none;
							margin: 0;
						}
						.react-tabs__tab {
							font-size: 1.2vw;
							font-style: normal;
							font-weight: 500;
							margin-right: 1.5vw;
							color: #000;
						}
						.react-tabs__tab--selected {
							border: none !important;
							border-bottom: 0.4vw solid #61bcac !important;
							padding-bottom: 1vw;
						}
						&_tabpanel1 {
							&_friendscards {
								display: flex;
								flex-wrap: wrap;
								padding: 2vw 0vw 3vw 0vw;
							}
							&_friendscard {
								cursor: pointer;
								width: 42%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
								border-radius: 0.2vw;
								margin: 1vw;
								padding: 0.8vw;
								img {
									width: 4vw;
									height: 4vw;
									object-fit: cover;
									border-radius: 50%;
								}
								.friendscard-content {
									p {
										color: #9a9a9a;
										font-size: 1.1vw;
										font-style: normal;
										font-weight: 500;
										font-family: Poppins, sans-serif;
									}
								}
								.friendscard-buttons {
									width: 30%;
									display: flex;
									justify-content: flex-end;
									button {
										width: fit-content;
										// height: 2vw;
										border-radius: 2vw;
										background: #61bcac;
										border: none;
										color: #fff;
										padding: 0.2vw 0.7vw;
										font-size: 0.9vw;
										font-style: normal;
										font-weight: 500;
										font-family: Poppins, sans-serif;
										cursor: pointer;
									}
								}
							}
						}
						&_tabpanel2 {
							&_request {
								display: flex;
								flex-wrap: wrap;
								align-items: center;
								padding: 2.5vw 0vw 3vw 0vw;
							}
							&_one {
								cursor: pointer;
								width: 42%;
								display: flex;
								align-items: center;
								box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
								border-radius: 0.2vw;
								margin: 1vw;
								padding: 1vw 1vw;
								.request-image {
									display: flex;
									align-items: center;
									width: 20%;
									margin: 0 0 0 0.8vw;
									img {
										width: 4vw;
										height: 4vw;
										object-fit: cover;
										border-radius: 50%;
									}
								}
								.request-content {
									width: 80%;
									display: flex;
									justify-content: space-between;
									flex-direction: row;
									p {
										display: flex;
										align-items: center;
										color: #9a9a9a;
										font-size: 1vw;
										font-style: normal;
										font-weight: 500;
										font-family: Poppins, sans-serif;
									}
									.request-buttons {
										display: flex;
										justify-content: space-between;
										align-items: center;
										width: 40%;
										.btn1 {
											width: fit-content;
											height: 2vw;
											border-radius: 2vw;
											background: #61bcac;
											border: none;
											color: #fff;
											font-size: 0.9vw;
											font-style: normal;
											font-weight: 500;
											font-family: Poppins, sans-serif;
											cursor: pointer;
										}
										.btn2 {
											width: fit-content;
											height: 2vw;
											border-radius: 2vw;
											background: #d0d0d0;
											border: none;
											color: #fff;
											font-size: 0.9vw;
											font-style: normal;
											font-weight: 500;
											font-family: Poppins, sans-serif;
											cursor: pointer;
										}
									}
								}
							}
						}
						&_tabpanel3 {
							&_sent {
								display: flex;
								flex-wrap: wrap;
								align-items: center;
								padding: 2.5vw 0vw 3vw 0vw;
							}
							&_first {
								cursor: pointer;
								width: 42%;
								display: flex;
								align-items: center;
								box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
								border-radius: 0.2vw;
								margin: 1.5vw;
								padding: 1vw 1vw;
								.sent-image {
									display: flex;
									align-items: center;
									width: 20%;
									margin: 0 0 0 1vw;
									img {
										width: 4vw;
										height: 4vw;
										object-fit: cover;
										border-radius: 50%;
										margin-right: 1vw;
									
									}
								}
								.sent-content {
									width: 30%;
									p {
										color: #9a9a9a;
										font-size: 1.1vw;
										font-style: normal;
										font-weight: 500;
										font-family: Poppins, sans-serif;
									}
								}
								.sent-buttons {
									width: 45%;
									display: flex;
									justify-content: flex-end;
									cursor: pointer;
									button {
										cursor: pointer;
										width: fit-content;
										height: 2vw;
										border-radius: 2vw;
										background: #61bcac;
										border: none;
										color: #fff;
										padding: 0 0.8vw;
										font-size: 0.9vw;
										font-weight: 500;
										font-style: normal;
										font-family: Poppins, sans-serif;

									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 760px) {
	.privatefriend {
		&_container {
			width: 100%;
		}
		&_user {
			padding: 0 0 30px 0;
			display: flex;
			justify-content: center;
			flex-direction: column;
			&_details {
				padding: 2vw 0;
				display: flex;
				justify-content: center;
				flex-direction: column;
				img {
					width: 150px;
					height: 150px;
					border-radius: 50%;
					margin: 10px 0;
				}
				&_content {
					padding: 0 0 0 10px;
					&_name {
						display: flex;
						justify-content: center;
						h3 {
							font-size: 36px;
							font-weight: 600;
							padding-bottom: 5px;
						}
					}
					&_follow {
						display: flex;
						align-items: center;
						span {
							font-size: 15px;
							color: #696666;
						}
						p {
							padding: 1px 4px 0 4px;
							font-size: 14px;
							color: #696666;
						}
					}
					&_images {
						padding: 1vw 0 0 0;
						img {
							width: 2.5vw;
							height: 2.5vw;
						}
					}
				}
			}
			&_data {
				&_sections {
					&_right {
						&_tabsection {
							box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
							margin-inline: 12px;
							border-radius: 6px;
							.tablist-custom {
								display: flex;
								justify-content: space-between;
								padding: 0.5vw 1vw 0 1vw;
								margin: 1.5vw 0 0;
								border-radius: 6px;
								border-bottom: 1px solid #ebebeb;
								background: #fff;
								// .tabimage {
								//   width: 12vw;

								//   img {
								//     width: 100%;
								//   }
								// }
								.tabnames {
									width: 100%;
									display: flex;
									justify-content: space-around;
								}
							}
							.react-tabs__tab-list {
								border-radius: 0.3vw;
								border-bottom: none;
								margin: 0;
							}
							.react-tabs__tab {
								color: #000;
								font-family: Poppins;
								font-size: 15px;
								font-style: normal;
								font-weight: 600;
								line-height: normal;
								margin-right: 0;
								margin-left: 0;
							}
							.react-tabs__tab--selected {
								border: none !important;
								border-bottom: 3px solid #61bcac !important;
								padding-bottom: 1vw;
							}
							&_tabpanel1 {
								&_friendscards {
									display: flex;
									flex-wrap: wrap;
									justify-content: center;
									padding: 30px 5px;
									margin-top: 5px;
								}
								&_friendscard {
									width: 100%;
									display: flex;
									align-items: center;
									box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
									border-radius: 5px;
									margin: 6px;
									padding: 14px 10px;
									column-gap: 12px;

									.friendscard-image {
										display: flex;
										align-items: center;
										img {
											width: 45px;
											height: 45px;
											object-fit: cover;
										}
									}
									.friendscard-content {
										width: 40%;
										p {
											color: #9A9A9A;
											font-family: Poppins;
											font-size: 16px;
											font-style: normal;
											font-weight: 500;
											line-height: normal;
										}
									}
									.friendscard-buttons {
										width: fit-content;
										display: flex;
										

										button {
											width: fit-content;
											height: fit-content;
											border-radius: 25px;
											background: #61bcac;
											border: none;
											padding: 4px 8px;
											color: #fff;
											font-family: Poppins;
											font-size: 14px;
											font-style: normal;
											font-weight: 500;
											line-height: normal;
										}
									}
								}
							}
							&_tabpanel2 {
								&_request {
									display: flex;
									flex-wrap: wrap;
									justify-content: center;
									padding: 2vw 0 3vw 0;
									margin-top: 5px;
								}
								&_one {
									width: 100%;
									display: flex;
									align-items: center;
									box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
									border-radius: 5px;
									margin: 6px 12px;
									padding: 14px 10px;
									column-gap: 14px;
									.request-image {
										display: flex;
										align-items: center;
										width: 25%;
										img {
											width: 60px;
											height: 60px;
											object-fit: cover;
										}
									}
									.request-content {
										width: 100%;
										display: flex;
										justify-content: center;
										flex-direction: column;
										row-gap: 8px;
										p {
											color: #9A9A9A;
											font-family: Poppins;
											font-size: 15px;
											font-style: normal;
											font-weight: 500;
											line-height: normal;
										}
										.request-buttons {
											width: 100%;
											display: flex;
											justify-content: space-between;
											align-items: center;
											column-gap: 8px;
											button.btn1,
											button.btn2 {
												height: fit-content;
												padding: 4px 8px;
											}
											.btn1 {
												flex: 50%;
												border-radius: 25px;
												color: #fff;
												font-family: Poppins;
												font-size: 14px;
												font-style: normal;
												font-weight: 500;
												line-height: normal;
											}
											.btn2 {
												flex: 50%;
												border-radius: 25px;
												background: #d0d0d0;
												border: none;
												color: #fff;
												font-family: Poppins;
												font-size: 14px;
												font-style: normal;
												font-weight: 500;
												line-height: normal;
											}
										}
									}
								}
							}
							&_tabpanel3 {
								&_sent {
									display: flex;
									flex-wrap: wrap;
									justify-content: center;
									align-items: center;
									padding: 2.5vw 0vw 3vw 0vw;
								}
								&_first {
									width: 100%;
									display: flex;
									align-items: center;
									box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
									border-radius: 5px;
									margin: 6px 12px;
									padding: 14px 12px;
									justify-content: space-between;
									column-gap: 8px;
									.sent-image {
										display: flex;
										align-items: center;
										img {
											width: 45px;
											height: 45px;
											object-fit: cover;
										}
									}
									.sent-content {
										width: 45%;
										p {
											color: #9A9A9A;
											font-family: Poppins;
											font-size: 15px;
											font-style: normal;
											font-weight: 500;
											line-height: normal;
											overflow: hidden;
											text-overflow: ellipsis;
										}
									}
									.sent-buttons {
										width: 45%;
										display: flex;
										// justify-content: center;
										button {
											width: fit-content;
											height: fit-content;
											border-radius: 25px;
											background: #61bcac;
											border: none;
											padding: 4px 8px;
											color: #fff;
											font-family: Poppins;
											font-size: 14px;
											font-style: normal;
											font-weight: 500;
											line-height: normal;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
