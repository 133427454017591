@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.search {
  &_message {
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 1vw 1vw;

    img {
      width: 3.4vw;
      height: 3.4vw;
      margin: 0 1vw 0 0;
      border-radius: 50%;
    }

    input {
      width: 100%;
      padding: 0.8vw 0 0.8vw 1.5vw;
      border: none;
      border: 1px solid #d1c3c3;
      border-radius: 3vw;
      font-size: 1vw;
      color: #9f9f9f;
    }
  }
}

.searchpopup {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.91);
  z-index: 999;
  overflow-y: scroll;

  &_containers {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 1vw 2vw 2vw;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    width: 40%;
    display: flex;
    flex-direction: column;
  }

  &_mainhead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1vw;
    border-bottom: 1px solid #d6cece;
    width: 96%;
    padding: 0.5vw;

    img {
      width: 2vw;
      cursor: pointer;
    }

    p {
      text-align: center;
      width: 100%;
      color: #000;
      font-family: Poppins, sans-serif;
      font-size: 1.2vw;
      font-style: normal;
      font-weight: 400;
    }
  }

  &_george {
    padding: 1.5vw 0 1vw 0vw;

    .hemant {
      width: 96%;
      font-family: Inter;
      font-size: 1vw;
      font-weight: 500;
      border: none;
      color: #5a5757;
      outline: none;
      resize: none;
      display: flex;
      align-items: center;
    }

    .Edit {
      display: flex;
      align-items: center;
      padding-bottom: 1vw;

      p {
        color: #000;
        font-family: Inter;
        font-size: 1vw;
        font-weight: 600;
        margin-left: 1vw;
      }

      img {
        width: 4vw;
        height: 4vw;
        border-radius: 50%;
      }
    }
  }

  &_postbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5vw;
    border: 1px solid #ccc;
    margin-bottom: 1vw;

    input {
      color: gray;
      border: 1px solid #ccc;
      width: 96%;
      font-family: Inter;
      font-size: 1.2vw;
      font-weight: 500;
      padding: 1vw 1vw;
      border-right: none;
      border: none;
      border-bottom-left-radius: 0.5vw;
      border-top-left-radius: 0.5vw;
    }

    &_picture {
      width: 13%;
      display: flex;
      justify-content: flex-end;

      img {
        width: 1.8vw;
        margin-right: 1vw;
        cursor: pointer;
      }
    }
  }

  &_postbutton {
    display: flex;
    justify-content: center;
    margin-top: 1vw;

    button {
      padding: 0.8vw;
      width: 100%;
      border: none;
      border-radius: 2vw;
      background: #61bcac;
      color: white;
      font-size: 1.5vw;
      font-family: Inter;
      cursor: pointer;
    }
  }

  .selected-file {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      // width: 10vw;
      // height: 10vw;
    }
  }
}

.cropper-modal {
  // top:30%;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.91);


  &_container {
    // width: 90%;
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // background: white;

  }

 
}


@media screen and (max-width: 760px) {
  .search {
    margin: 5vw 0 7vw;

    &popup {
      display: flex;
      align-items: center;

      &_containers {
        top: auto;
        left: auto;
        transform: none;
        width: -webkit-fill-available;
        margin-inline: 12px;
        padding: 12px;
        border-radius: unset;
      }

      &_mainhead {
        width: auto;

        p {
          color: #000;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-block-end: 16px;
        }

        img.close-button {
          width: 24px;
        }
      }

      &_george {
        padding: 0;
        margin-block:24px;

        .Edit {
          column-gap: 16px;
          padding: 0;
          margin-block-end: 24px;

          p {
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;
          }

          img {
            width: 65px;
            height: 65px;
            aspect-ratio: 1;
          }
        }

        .hemant {
          color: #8B8484;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 0;
        }
      }

      &_postbox {
        margin-block-end: 24px;
        border-radius: 10px;
        padding: 12px;
        column-gap: 12px;
        
        input {
          width: 100%;
          background-color: inherit;
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 0;

          &::placeholder {
            color: #A38C8C;
          }
        }

        &_picture {
          img {
            height: 24px;
            width: auto;
            aspect-ratio: 1;
            margin: 0;
          }
        }
      }

      &_postbutton {
        margin: 0;

        button {
          color: #FFF;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 60px;
          padding: 10px;
        }
      }
    }

    &_message {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 46px;
        height: 46px;
        margin-right: 10px;
      }

      input {
        width: 75%;
        padding: 10px 15px 10px 15px;
        font-size: 13px;
        border-radius: 20px;
        font-family: "Poppins", sans-serif;
      }
    }
  }
}