
@media screen and (max-width: 768px) {
	*{
		font-family: "Inter", sans-serif;
	}
	.red-dot {
		position: absolute;
		top: 0;
		left: 2.5vw;
		height: 8px;
		width: 8px;
		background-color: red;
		border-radius: 50%;
	  }
	  
	.notifications {
		position: relative;
		display: flex;
		flex-direction: column;

		&_dropdown {
			background-color: #fff;
			padding: 2vw;
			border-radius: 1vw;

			&_container {
				display: flex;
				flex-direction: column;

				h5 {
					font-size: 5vw;
					font-weight: 500;
					margin-bottom: 2vw;
				}
			}

			&_loader {
				display: flex;
				justify-content: center;

				.loader {
					display: inline-block;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					-webkit-animation: spin 3s linear infinite;
					animation: spin 3s linear infinite;
				}

				@-webkit-keyframes spin {
					0% {
						-webkit-transform: rotate(0deg);
					}

					100% {
						-webkit-transform: rotate(360deg);
					}
				}

				@keyframes spin {
					0% {
						transform: rotate(0deg);
					}

					100% {
						transform: rotate(360deg);
					}
				}
			}

			&_section {
				margin-bottom: 0.5vw;

				h6 {
					font-size: 3.5vw;
					color: #256f61;
					font-weight: 500;
				}

				&_content {
					display: flex;
					justify-content: flex-start;
					margin: 1.5vw 0;

					&_left {	
						img {
							border-radius: 50%;
							width: 10vw;
							height: 10vw;
						}
					}

					&_right {
						min-width: min-content;
						margin-left: 1vw;

						p {
							font-size: 3vw;
						}

						span {
							font-size: 3vw;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}