.header {
	&_mobile {
		display: none;
	}
}

.home-header {
	background: #e8f8f5;
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 999;

	&_container {
		display: flex;
		justify-content: center;
	}

	&_sections {
		width: 90%;
		display: flex;
		align-items: center;
	}

	&_left {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 50%;
		margin-right: 3vw;

		&_menu {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			.logo_wrapper {
				margin: 1.2vw 0;
				padding-right: 1vw;
				border-right: 1px solid #cbc3c3;
			}

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 1vw;

				img {
					width: 1vw;
					height: 1vw;
				}

				span {
					color: #256e61;
					font-size: 1vw;
					font-weight: 600;
					padding: 0 0 0 0.4vw;
					text-transform: capitalize;
				}
			}
		}

		&_mobilemenu {
			display: none;
		}
	}

	&_right {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-left: 3vw;

		&_input {
			width: 30%;

			input {
				width: 100%;
			}
		}

		&_links {
			width: 80%;

			ul {
				display: flex;
				justify-content: space-between;
				align-items: center;

				li {
					display: flex;

					a {
						display: flex;
						align-items: center;

						img {
							height: 18px;
						}

						span {
							font-size: 0.8vw;
							padding: 0 0 0 0.4vw;
						}
					}
				}

				.message {
					position: relative;
					display: flex;

					.message-dot {
						width: 0.1vw;
						height: 0.5vw;
						background-color: red;
						border-radius: 50%;
						position: absolute;
						bottom: 0.6vw;
						left: 0.7vw;

					}
				}

				.notifications {
					position: relative;
					display: flex;
					flex-direction: column;

					.notification-dot {
						width: 0.1vw;
						height: 0.5vw;
						background-color: red;
						border-radius: 50%;
						position: absolute;
						bottom: 0.6vw;
						left: 0.4vw;

					}

					&_dropdown {
						box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
						background-color: #fff;
						position: absolute;
						top: 3vw;
						left: 0;
						z-index: 1;
						padding: 1vw;
						border-radius: 0.6vw;

						&_content {
							max-height: 35vw;
							overflow-y: scroll;
						}

						&_container {
							display: flex;
							flex-direction: column;
							width: 15vw;

							h5 {
								color: #000;
								font-size: 1.3vw;
								font-weight: 500;
								margin-bottom: 1vw;
							}


						}

						&_loader {
							display: flex;
							justify-content: center;

							.loader {
								display: inline-block;
								width: 50px;
								height: 50px;
								border-radius: 50%;
								-webkit-animation: spin 3s linear infinite;
								animation: spin 3s linear infinite;
							}

							@-webkit-keyframes spin {
								0% {
									-webkit-transform: rotate(0deg);
								}

								100% {
									-webkit-transform: rotate(360deg);
								}
							}

							@keyframes spin {
								0% {
									transform: rotate(0deg);
								}

								100% {
									transform: rotate(360deg);
								}
							}
						}

						&_section {
							h6 {
								color: #256f61;
								font-size: 1vw;
								font-weight: 400;


							}

							&_content {
								display: flex;
								width: 15vw;
								margin: 0.5vw 0;
								cursor: pointer;

								&_left {
									width: 20%;

									img {
										border-radius: 50%;
										width: 2.5vw;
										height: 2.5vw;
									}
								}

								&_right {
									margin-left: 1vw;
									width: 85%;

									p {
										color: #8b8b8b;
										font-size: 1vw;
										font-weight: 400;
									}

									.date {
										color: #575757;
										font-size: 1vw;
										font-weight: 600;
									}
								}
							}
						}
					}
				}
			}
		}

		&_links2 {
			width: 10%;

			ul {
				display: flex;
				justify-content: space-between;

				li {
					display: flex;

					a {
						display: flex;
						align-items: center;

						img {
							width: 1vw;
							height: 1vw;
						}

						span {
							font-size: 0.8vw;
							text-transform: none;
							padding: 0 0 0 0.4vw;
						}
					}
				}
			}
		}
	}

	&_links {
		display: none;
	}
}

.headermenu {
	display: none;
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.header {
		&_mobile {
			position: sticky;
			top: 0;
			background: white;
			z-index: 1;
			width: 100%;
			display: flex;
			flex-direction: column;
			margin-bottom: 3vw;
			border-bottom: 2px solid #ececec;
			// padding: 0 0 3vw;

			&_top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.14);
				// margin-bottom: 3vw;
				padding: 2vw;
				position: relative;

				&_input {
					width: 90%;
					display: flex;

					img {
						width: 100%;
					}

					.css-148wedx-MuiFormControl-root-MuiTextField-root {
						width: 80%;
						border: 2px solid black !important;
					}
				}
			}

			.settings-logout {
				position: absolute;
				right: 5vw;
				top: 12vw;
				z-index: 1;

				&_contain {
					display: flex;
					background: white;
					padding: 2vw;
					box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.14);
					align-items: center;

					h5 {
						margin-left: 2vw;
					}
				}
			}

			&_bottom {
				display: flex;
				justify-content: center;
				background-color: #e8f8f5;

				hr {
					border: none;
					background: #ececec;
					width: 100%;
					height: 2px;
				}

				ul {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;

					
					li.message,li.notifications, li {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						min-width: 42px;

						a {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							padding: 12px;

							img {
								height: 18px;
							}

							span {
								font-size: 10px;
							}

							.message-dot {
								display: block;
								position: absolute;
								top: 0;
								right: 1vw;
								height: 8px;
								width: 8px;
								background-color: red;
								border-radius: 50%;
								z-index: 2;
							}

							&.active.show-active {
								background-color:#5E6B86;																					
							}							
						}

						&.notifications {
							& > div{
								padding: 12px;
								display: flex;
								justify-content: center;
								&.active {
									background-color: #5E6B86;
								}
							}
						}
						
					}
				}
			}

			.mobilenotifications {
				position: relative;
				display: flex;
				flex-direction: column;

				&_dropdown {
					width: 50%;
					box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
					background-color: #fff;
					position: absolute;
					// top: 6vw;
					// left: 20vw;
					top: 42vw;
					right: 3vw;
					z-index: 1;
					padding: 2vw;
					border-radius: 1vw;

					&_container {
						display: flex;
						flex-direction: column;

						h5 {
							font-size: 3.5vw;
							font-weight: 500;
							margin-bottom: 2vw;

						}
					}

					&_section {
						margin-bottom: 0.5vw;

						h6 {
							font-size: 2.8vw;
							color: #256f61;
							font-weight: 500;
						}

						&_content {
							display: flex;
							justify-content: flex-start;
							margin: 1.5vw 0;

							&_left {
								width: 8vw;

								img {
									border-radius: 50%;
									width: 100%;
								}
							}

							&_right {
								min-width: max-content;
								margin-left: 1vw;

								p {
									font-size: 2.5vw;
								}

								span {
									font-size: 2.5vw;
									font-weight: 600;
								}
							}
						}
					}
				}
			}
		}
	}

	.headermenu {
		display: block;
		display: flex;
		box-shadow: 0 0 4px 2px #0000002e;
	}

	.home-header {
		&_container {
			flex-direction: column;
			align-items: center;
		}

		&_sections {
			flex-direction: column;
			width: 95%;
		}

		&_left {
			width: 100%;
			justify-content: space-between;
			align-items: center;
			margin-inline: 0;

			&_menu {
				justify-content: flex-start;

				.img {
					width: 50%;
					padding-right: 4vw;
				}

				a {
					width: 30%;

					img {
						width: 5vw;
						height: 5vw;
					}

					span {
						font-size: 4vw;
						margin-left: 2vw;
					}
				}
			}

			&_mobilemenu {
				display: flex;
				justify-content: space-between;

				.searchimg {
					display: none;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}

			&_input {
				display: none;
			}
		}

		&_right {
			display: none;
		}

		&_links {
			display: none;

			ul {
				width: 85%;
				display: flex;
				justify-content: space-between;
				padding: 24px 0;

				li {
					a {
						display: flex;

						img {
							width: 20px;
							height: 20px;
						}

						span {
							display: none;
						}
					}
				}
			}
		}
	}
}