@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap");

// * {
// 	transition: 300ms all;
// }
:root {
	scroll-behavior: smooth;
}

body {
	margin: 0
}

.app {
	font-family: "Inter", sans-serif;
	height: 100dvh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

input {
	border-color: lightgrey;
	margin: 0;
}

input:focus {
	outline: none;
}

input:focus:after {
	outline: none;
}

button {
	font-family: "Inter", sans-serif;
}

ul {
	margin: 0;
	padding: 0;
}
.react-tabs__tab:focus:after {
	display: none;
}

li {
	list-style-type: none;
}

a {
	color: black;
	text-decoration: none;
}

.MuiInputBase-root::before {
	border-bottom: none !important;
}

.MuiInputBase-root::after {
	border-bottom: none !important;
}

span.MuiButtonBase-root.MuiRadio-root {
	padding: 0 0 12px 9px;
}

// button.MuiButtonBase-root.MuiTab-root.Mui-selected {
// 	color: black;
// 	// padding: 0.7vw 1vw 0.3vw !important;
// 	background-color: #c6e8ac;
// 	border-start-start-radius: 0.5vw;
// 	border-start-end-radius: 0.5vw;
// 	margin-right: 1vw;
// 	display: flex;
// 	flex-direction: row;
// 	min-height: 0;
// }

span.MuiTabs-indicator.css-11yukd5-MuiTabs-indicator {
	border: none !important;
	background-color: #c6e8ac;
	// height: 0;
}

// .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
// 	padding-top: 1vw;
// 	padding-left: 1.5vw;
// 	height: 40px;
// }

img.MuiTab-iconWrapper {
	width: 20px !important;
	height: 20px !important;
	margin-right: 0.5vw;
}
.MuiTabPanel-root {
	background: #fff;
	padding: 0 !important;
}

.css-1xdzgqr-MuiButtonBase-root-MuiRadio-root.Mui-checked {
	color: #61bcac !important;
}
.css-12q9u1p-MuiButtonBase-root-MuiRadio-root.Mui-checked {
	color: #61bcac !important;
}

span.MuiSlider-root {
	color: #5b21ff !important;
}

span.MuiSlider-thumb {
	color: #fff !important;
}

