.privateprofile {
  .ptag {
    margin-top: 1vw;
    margin-left: 1vw;
    width: 100%;
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 500;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  &_container {
    width: 68%;
    padding: 0 0 4vw 0;
  }

  &_banner {
    width: 100%;

    img {
      // height: 380px;
      max-height: 32vw;
      width: 100%;
      object-fit: cover;
    }
  }

  &_user {
    padding: 0 0 3vw 0;

    &_details {
      padding: 2vw 0;
      display: flex;
      align-items: center;

      img {
        width: 7vw;
        height: 7vw;
        border-radius: 50%;
      }

      &_content {
        padding: 0 0 0 2vw;

        &_name {
          h3 {
            font-size: 1.6vw;
            font-weight: 600;
            padding-bottom: 0.4vw;
          }
        }

        &_follow {
          display: flex;

          span {
            font-size: 1.1vw;
            color: #696666;
          }

          p {
            padding: 0 0.5vw;
            font-size: 1.1vw;
            color: #696666;
          }
        }

        &_images {
          padding: 1vw 0 0 0;

          img {
            width: 2.5vw;
            height: 2.5vw;
          }
        }
      }
    }

    &_data {
      &_sections {
        display: flex;
        justify-content: space-between;

        &_left {
          width: 36%;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          height: max-content;
          overflow-y: auto;

          &_container {
            display: flex;
            flex-direction: column;
          }

          &_intro {
            padding: 1vw 1vw 1.5vw 1vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 6px;
            border: 1px solid #ebebeb;
            background: #fff;
            box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

            &_heading {
              width: 99%;

              h4 {
                font-size: 1.4vw;
                font-weight: 400;
              }
            }

            &_button {
              width: 100%;
              padding: 1vw 0;
              cursor: pointer;

              button {
                width: 100%;
                cursor: pointer;
                background: #d7d7d7;
                border-radius: 5vw;
                font-size: 0.8vw;
                font-weight: 500;
                padding: 0.6vw 0;
                border: none;
              }
            }

            &_content {
              width: 100%;

              p {
                font-size: 0.8vw;
                color: #828282;
                padding: 0.2vw 0;

                span {
                  color: black;
                  padding: 0 0.2vw 0 0;
                }
              }
            }
          }

          &_links {
            padding: 1vw 1vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1.8vw 0;
            border-radius: 0.3vw;
            border: 1px solid #ebebeb;
            background: #fff;
            box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

            &_heading {
              width: 99%;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              h4 {
                font-size: 1.4vw;
                font-weight: 400;
              }

              span {
                font-size: 0.8vw;
                color: #958585;
                border-bottom: 1px solid #958585;
              }
            }

            &_content {
              width: 100%;
              padding: 0.5vw 0 1vw 0;

              p {
                font-size: 0.8vw;
                color: #828282;
                padding: 0.2vw 0;
                font-weight: 500;

                span {
                  color: black;
                  padding: 0 0.2vw 0 0;
                }
              }
            }
          }

          &_photos {
            padding: 1vw 0.6vw 1.5vw 0.6vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 0.3vw;
            border: 1px solid #ebebeb;
            background: #fff;
            box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

            &_heading {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              h4 {
                font-size: 1.4vw;
                font-weight: 400;
              }

              span {
                font-size: 0.8vw;
                color: #958585;
                border-bottom: 1px solid #958585;
              }
            }

            &_container {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              padding: 0.6vw 0 0 0;

              img {
                width: calc(25% - 0.5vw);
                margin: 0.6vw 0.5vw 0 0;
                border-radius: 0.8vw;
              }

              img:nth-child(4n) {
                margin: 0.6vw 0 0 0;
                border-radius: 0.8vw;
              }
            }
          }

          &_friend {
            margin: 2vw 0;
            padding: 1vw 0.6vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 6px;
            border: 1px solid #ebebeb;
            background: #fff;
            box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

            &_heading {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              h4 {
                font-size: 1.4vw;
                font-weight: 400;
              }

              span {
                font-size: 0.8vw;
                color: #958585;
                border-bottom: 1px solid #958585;
              }
            }

            &_container {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              padding: 0.6vw 0 0 0;

              img {
                width: 5vw;
                margin: 0.6vw 0.6vw 0 0;
                border-radius: 0.8vw;
                height: 5vw;
                cursor: pointer;
              }

              img:nth-child(4n) {
                width: 5vw;
                margin: 0.6vw 0 0 0;
                border-radius: 0.8vw;
                height: 5vw;
                cursor: pointer;
              }
            }
          }
        }

        &_right {
          width: 60%;

          &_tabsection {
            .tablist-custom {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0.5vw 1vw 0 1vw;
              margin: 1.5vw 0 0;
              border-radius: 6px;
              border: 1px solid #ebebeb;
              background: #fff;
              box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

              .tabimage {
                width: 12vw;
                text-align: end;

                .react-tabs__tab {
                  padding-top: 0;
                  margin-right: 0;
                }

                img {
                  width: 90%;
                }
              }
            }

            .react-tabs__tab-list {
              border-radius: 0.3vw;
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.2784313725);
            }

            .react-tabs__tab {
              font-size: 1.4vw;
              font-style: normal;
              font-weight: 400;
              margin-right: 1.5vw;
            }

            .react-tabs__tab--selected {
              border: none !important;
              border-bottom: 0.4vw solid #61bcac !important;
              padding-bottom: 1.3vw;
            }
          }

          &_container {
            display: flex;
            flex-direction: column;
          }

          &_filter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #f9f9f9;
            padding: 2vw 3vw;
            margin: 1vw 0 0 0;
            border-radius: 0.3vw;

            h4 {
              font-size: 1.4vw;
              font-weight: 400;
            }

            img {
              width: 8vw;
            }
          }
        }
      }
    }
  }
}

.privatepopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.91);
  z-index: 999;
  overflow-y: scroll;

  &_container {
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 1vw 1vw 2vw 1vw;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d6cece;
    width: 96%;
    padding: 0.5vw;

    img {
      width: 2vw;
      cursor: pointer;
    }

    p {
      text-align: center;
      width: 90%;
      color: #000;
      font-family: Poppins;
      font-size: 1.2vw;
      font-style: normal;
      font-weight: 400;
    }
  }

  &_heading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;

    .Edit {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 0.6vw;

      p {
        color: #000;
        font-family: Poppins;
        font-size: 1vw;
        font-style: normal;
        font-weight: 400;
      }

      img {
        width: 1.2vw;
        height: 1.2vw;
      }
    }
  }

  &_picture {
    margin-bottom: 2vw;

    img {
      border-radius: 50%;
      width: 12vw;
      height: 12vw;
    }
  }

  &_cropper-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.91);


    &_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;

    }

    h3 {
      color: black;
      padding: 1vw;
      font-weight: 500;

    }

    &_titleborder {
      border-bottom: 1px solid #D6CECE;
      width: 92%;
      margin-bottom: 1vw;
    }

    &_avatarandbuttonsection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &_avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;

      .avatar-container-parent {
        width: 100% !important;
      }

      .avatar-container {
        width: 100% !important;

        &_section {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100% !important;

          img {
            width: 3vw;
            height: 2vw;
          }

          h3 {
            color: #9C9595;
            margin-top: 10px;
            line-height: 0;
          }
        }
      }
    }

    &_separator {
      border-left: 2px solid #252424;
      margin: 0 16px;
      height: 100%;
    }

    &_buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 16px 0;

      .save {
        width: 8vw;
        padding: 0.5vw;
        border-radius: 50px;
        border: none;
        background: #61bcac;
        color: white;
        cursor: pointer;
      }

      .cancel {
        width: 8vw;
        border-radius: 50px;
        background: #d9d9d9;
        color: #857c7c;
        border: none;
        cursor: pointer;
      }
    }
  }


  &_cover {
    width: 90%;
    padding: 0 0 1.5vw 0;

    img {
      width: 100%;
      object-fit: contain;
      border-radius: 1vw;
    }
  }

  &_names {
    width: 100%;
    padding: 0.6vw 0;

    img {
      width: 1.2vw;
    }

    .name {
      h4 {
        color: #000;
        font-family: Poppins;
        font-size: 1vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      p {
        color: #a3a1a1;
        font-family: Poppins;
        font-size: 1vw;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0.5vw;
      }

      input {
        outline: none;
        margin-top: 0.5vw;
        width: 90%;
        padding: 0.5vw 0 0.5vw 0.8vw;
        font-size: 1vw;
        border-radius: 1vw;
      }
    }
  }

  &_password {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    padding: 0.6vw 0;
    border-bottom: 1px solid #d6cece;

    img {
      width: 1.2vw;
    }

    .pass {
      h4 {
        color: #000;
        font-family: Poppins;
        font-size: 1vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      p {
        color: #a3a1a1;
        font-family: Poppins;
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &_but {
    width: 90%;
    margin-top: 1vw;

    .section {
      display: flex;
      justify-content: flex-end;

      button {
        width: 8vw;
        height: 2.5vw;
        border: none;
        border-radius: 2vw;
        background: #61bcac;
        color: white;
        font-size: 1vw;
        cursor: pointer;
      }

      .cancel {
        width: 8vw;
        height: 2.5vw;
        border: none;
        border-radius: 2vw;
        background: #d9d9d9;
        color: #857c7c;
        margin-right: 1vw;
      }
    }
  }

  .file-selector-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.91);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .file-selector-modal_container {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      h3 {
        margin-bottom: 10px;
      }

      input[type="file"] {
        margin-bottom: 10px;
      }

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: #007bff; // Example blue color
        color: white;
        cursor: pointer;

        &:hover {
          background-color: #0056b3; // Darker blue on hover
        }
      }
    }
  }



}

@media screen and (min-width: 320px) and (max-width: 760px) {
  .open-class {
    display: block;
  }

  .privateprofile {
    &_container {
      width: 100%;
      padding: 0;
    }

    &_banner {
      img {
        max-height: 200px;
        object-fit: cover;
      }
    }

    &_user {
      padding: 8vw 18px;

      &_details {
        padding: 4vw 0;
        flex-direction: column;

        &_image {
          img {
            width: 180px;
            height: 180px;
          }
        }

        &_content {
          padding: 4vw 0 0 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          &_name {
            h3 {
              font-size: 36px;
            }
          }

          &_follow {
            padding: 2vw 0;

            span {
              font-size: 16px;
            }

            p {
              font-size: 19px;
              padding: 0 6px;
            }
          }

          &_images {
            img {
              width: 46px;
              height: 46px;
            }
          }
        }
      }

      &_data {
        &_sections {
          flex-direction: column;

          &_left {
            width: 100%;
            position: static;

            &_intro {
              padding: 6vw 15px;

              &_heading {
                padding: 0 0 0 8px;

                h4 {
                  font-size: 26px;
                }
              }

              &_button {
                padding: 12px 0;

                button {
                  padding: 8px 0;
                  font-size: 15px;
                  font-weight: 500;
                  border: none;
                }
              }

              &_content {
                width: 90%;
                padding: 6px 0;

                p {
                  font-size: 15px;
                  padding: 6px 0;
                }
              }
            }

            &_links {
              padding: 12px 0;

              &_heading {
                align-items: center;
                width: calc(100% - 24px);
                padding: 0 8px 8px 16px;

                h4 {
                  font-size: 26px;
                }

                span {
                  font-size: 14px;
                }
              }

              &_content {
                width: calc(100% - 16px);
                padding: 0 0 0 16px;

                p {
                  font-size: 14px;
                  padding: 0 0 8px 0;
                }
              }
            }

            &_photos {
              margin: 18px 0 12px 0;
              padding: 6vw 15px;

              &_heading {
                padding: 0 0 0 8px;
                align-items: center;

                h4 {
                  font-size: 26px;
                }

                span {
                  font-size: 14px;
                }
              }

              &_container {
                padding: 4vw 0 0 0;

                img:nth-child(4n) {
                  margin: 0 12px 12px 0;
                }

                img:nth-child(3n) {
                  margin: 0 0 12px 0;
                }

                img {
                  width: calc(33.3% - 12px);
                  margin: 0 12px 12px 0;
                }
              }
            }

            &_friend {
              padding: 6vw 15px;

              &_heading {
                align-items: center;
                padding: 0 0 0 8px;

                h4 {
                  font-size: 26px;
                }

                span {
                  font-size: 14px;
                }
              }

              &_container {
                padding: 4vw 0 0 0;

                img:nth-child(4n) {
                  width: calc(33.3% - 12px);
                  margin: 0 12px 12px 0;
                  height: 100px;
                  border-radius: 10px;
                }

                img:nth-child(3n) {
                  width: calc(33.3% - 12px);
                  margin: 0 0 12px 0;
                  height: 100px;
                  border-radius: 10px;
                }

                img {
                  width: calc(33.3% - 12px);
                  margin: 0 12px 12px 0;
                  height: 100px;
                  border-radius: 10px;
                }
              }
            }
          }

          &_right {
            width: 100%;

            &_tabsection {
              .tablist-custom {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .tabimage {
                  width: 5vw;

                  img {
                    width: 80px;
                    height: 28px;
                  }
                }
              }

              .react-tabs__tab {
                font-size: 15px;
              }

              .react-tabs__tab--selected {
                border: none !important;
                border-bottom: 1vw solid #61bcac !important;
                padding-bottom: 15px;
              }
            }

            &_filter {
              margin: 6px 0;
              padding: 4vw 15px;

              h4 {
                font-size: 18px;
              }

              img {
                width: 100px;
              }
            }
          }
        }
      }
    }
  }

  .privatepopup {
    &_container {
      top: 60%;
      width: 80%;
    }

    &_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #d6cece;
      width: 96%;
      padding: 0.5vw;

      img {
        width: 20px;
        cursor: pointer;
      }

      p {
        text-align: center;
        width: 90%;
        color: #000;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
      }
    }

    &_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;

      .Edit {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 0.6vw;

        p {
          font-size: 14px;
          font-weight: 400;
        }

        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    &_picture {
      img {
        border-radius: 50%;
        width: 100px;
        height: 100px;
      }
    }

    &_cropper-modal {
      &_titleborder {
        margin: 2vw 0 3vw;
      }

      &_avatar {
        .avatar-container {
          &_section {
            img {
              width: 8vw;
              height: 6vw;
            }
          }
        }
      }

      &_buttons {
        .save {
          width: 20vw;
        }

        .cancel {
          width: 20vw;
        }
      }
    }

    &_cover {
      width: 90%;
      padding: 1.5vw 0;

      img {
        width: 100%;
        height: 150px;
        border-radius: 12px;
      }
    }

    &_names {
      img {
        width: 18px;
      }

      .name {
        h4 {
          font-size: 14px;
          margin-left: 10px;
        }

        p {
          font-size: 12px;
          margin: 0 0 5px 10px;
        }

        input {
          outline: none;
          margin: 5px 0 0 10px;
          width: 90%;
          padding: 5px 0 5px 6px;
          font-size: 12px;
          border-radius: 14px;
        }
      }
    }

    &_password {
      width: 90%;
      padding: 0.6vw 0;

      img {
        width: 14px;
      }

      .pass {
        h4 {
          font-size: 14px;
        }

        p {
          font-size: 12px;
        }
      }
    }

    &_but {
      width: 90%;
      margin-top: 12px;

      .section {
        button {
          width: 70px;
          height: 25px;
          border-radius: 25px;
          font-size: 12px;
        }

        .cancel {
          width: 70px;
          height: 25px;
          border-radius: 25px;
          font-size: 12px;
        }
      }
    }
  }
}