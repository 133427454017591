.mbsc {
    &_pop {
      display: flex;
      justify-content: center;
      align-items: center;
      &_container {
        background-color: #ffffff;
        width: 100%;
      }
      &_section {
				cursor: pointer;
        &_input {
          display: flex;
          border-radius: 2vw;
          box-shadow: 0 0 8px #b9babd;
          input {
            outline: none;
            width: 100%;
            padding: 0.5vw 0;
            font-size: 1vw;
            border-radius: 2vw;
          }
          .MuiInputBase-root{
            border-radius: 2vw;
            padding: 0.2vw 1vw;
            background-color: #FFF;
          }
        }
        &_cards {
					margin-block: 14px;
          box-shadow: 0 0 8px #b9babd;
          padding: 1vw 0;
          border-radius: 0.5vw;
          &_content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0 1vw;
            border-bottom: 1px solid lightgray;
            &:last-child {
              border-bottom: none;
            }
            &_center {
              padding: 0 0.5vw;
              display: flex;
              justify-content: flex-start;
              width: 75%;
              &_image {
                img {
                  width: 4vw;
                  height: 4vw;
                  border-radius: 50%;
                  margin: 1.5vw 1.5vw 1.5vw 0;
                }
              }
              &_content {
                display: flex;
                flex-direction: column;
                align-self: center;
                h2 {
                  display: flex;
                  align-items: center;
                  font-size: 1.2vw;
                  font-weight: 700;
                  color: #000000;
                  margin-bottom: 0.3vw;
                }
                h5 {
                  white-space: wrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 0;
                  width: 95%;
                  font-size: 0.8vw;
                  color: #8e8e8e;
                  font-weight: 400;
                  position: relative;
              
                  &.show-truncated {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  }
              
                  .toggle {
                    color: lightgray; 
                    cursor: pointer;
                    display: block;
                    margin-top: 0.5rem;
                  }
                }
                p {
                  color: #8e8e8e;
                  font-size: 0.7vw;
                  font-weight: 400;
                  padding-top: 0.5vw;
                }
              }
            }
            &_right {
              display: flex;
              align-self: center;
              button {
                background-color: #61bcac;
                color: #ffffff;
                border-radius: 20px;
                border: none;
                padding: 0.5vw;
                font-size: 0.9vw;
                width: 6vw;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  
  @media screen and (min-width: 320px) and (max-width: 760px) {
    .mbsc {
      &_pop {
        &_container {
          width: 100%;
          margin-top: 12px;
        }
        &_section {
          &_input {
            border-radius: 4vw;
            width: 90%;
            margin-top: 20px;
            margin-left: 5vw;
            input {
              width: 70%;
              padding: 5px 0 5px 6px;
              font-size: 12px;
              border-radius: 4vw;
            }
            .MuiInputBase-root{
              border-radius: 4vw;
            }
          }
          &_cards {
            margin: 10px;
            &_content:last-child {
              margin-bottom: 30px;
            }
            &_content {
              margin: 12px;
              border-bottom: none;
              &_center {
                padding: 0 1vw;
                display: flex;
                justify-content: flex-start;
                &_image {
                  margin-right: 20px;
                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                  }
                }
                &_content {
                  h2 {
                    font-size: 12px;
                  }
                  h5 {
                    font-size: 10px;
                  }
                  p {
                    font-size: 8px;
                    font-weight: 400;
                    padding-top: 0.5vw;
                  }
                }
              }
              &_right {
                align-self: baseline;
                button {
                  border-radius: 25px;
                  padding: 4px 2px;
                  font-size: 10px;
                  width: 70px;
                }
              }
            }
          }
        }
      }
    }
  }
  