.react-tabs__tab-list {
	// border-radius: 0.3vw;
	border-bottom: 1px solid #dccece;
	// margin: 0;
}
.react-tabs__tab {
	font-size: 1.2vw;
	font-style: normal;
	font-weight: 500;
	margin-right: 1.5vw;
	margin-left: 1.5vw;

    color: #000;
  }
  .react-tabs__tab--selected {
    // border: none !important;
    border-bottom: 0.4vw solid #61bcac !important;
    padding-bottom: 0.5vw;
  }

@media screen and (min-width: 320px) and (max-width: 760px) {
	.tabsection {
		width: 100%;
	}
	.react-tabs__tab-list {
		border-radius: 0.3vw;
		// border-bottom: none;
	}
	.react-tabs__tab {
		margin-left: 2vw;
		color: #000;
		font-family: Poppins;
		font-size: 19px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	.react-tabs__tab--selected {
		border: none !important;
		border-bottom: 4px solid #61bcac !important;
		padding-bottom: 1vw;
	}
}
