@import "./variables.scss";
@import "./mixins.scss";
* {
	font-size: $base-font-size;
}

::-webkit-scrollbar {
  border-radius: 2px;
  width: 8px;
	height: 4px;
}

::-webkit-scrollbar-track {
  width: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $dimGrayLight;
  
}

::-webkit-scrollbar-thumb:hover {
  background: #CFCFCF; 
}
