.engineeringsignup {
	background: #ebebeb;
	&_container {
		display: flex;
		justify-content: center;
		padding: 5vw 2vw 6vw 2vw;
		&_content {
			border: 1px solid #d6cece;
			border-radius: 0.5vw;
			background: #fff;
			width: 70%;
			padding: 4vw 4vw;
			&_top {
				margin-bottom: 2vw;
				button {
					font-size: 1.5vw;
					padding: 0.7vw 0;
					color: #fff;
					width: 100%;
					background: #61bcac;
					border: none;
					border-radius: 0.3vw;
				}
			}
			&_middle {
				margin-bottom: 2vw;
				display: flex;
				justify-content: space-between;
				width: 100%;
				&_client {
					border: 1px solid #aba6a6;
					padding: 2.2vw 1.5vw;
					width: 26%;
					&_header {
						margin-bottom: 3vw;
						display: flex;
						justify-content: space-between;
						img {
							width: 3vw;
						}
					}
					p {
						font-size: 1.4vw;
						font-weight: 400;
						margin-bottom: 0.3vw;
					}
				}
			}
			&_bottom {
				margin-bottom: 2vw;
				display: flex;
				justify-content: center;
				button {
					font-size: 1.5vw;
					padding: 0.7vw 0;
					color: #fff;
					width: 30%;
					background: #61bcac;
					border: none;
					border-radius: 10vw;
				}
			}
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.engineeringsignup {
		background: #fff;
		&_container {
			padding: 0;
			margin: 9vw 6vw 8vw 6vw;
			display: block;
			&_content {
				width: 100%;
				padding: 0;
				border: none;
				&_top {
					margin-bottom: 10vw;
					button {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						padding: 3vw;
						border-radius: 2vw;
						span {
							font-size: 5vw;
							text-align: center;
							margin-bottom: 1vw;
						}
					}
				}
				&_middle {
					margin: 0;
					flex-direction: column;
					&_client {
						width: 100%;
						padding: 0;
						margin-bottom: 5vw;
						border-radius: 2vw;
						&_header {
							margin: 6vw 5vw;
							img {
								width: 8vw;
							}
						}
						&_details {
							margin: 0 5vw 6vw;
							p {
								font-size: 5vw;
								font-weight: 500;
							}
						}
						&:nth-child(3) {
							margin-bottom: 12vw;
						}
					}
				}
				&_bottom {
					display: block;
					button {
						width: 100%;
						padding: 4vw 0;
						span {
							font-size: 5vw;
						}
					}
				}
			}
		}
	}
}
