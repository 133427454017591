.sidenav {
  width: 100%;
	margin-top: 24px;
  position: -webkit-sticky;
  position: sticky;
  &_container {
    display: flex;
    flex-direction: column;
  }
  &_header {
    display: flex;
    align-items: center;
		column-gap: 12px;
    img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
    }
    h5 {
      color: #000;
			font-family: Poppins;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: 157%; /* 23.55px */
    }
  }
  &_head {
    display: none;
  }
  &_menu {
    &_heading {
      display: flex;
      align-items: center;
      padding-block: 12px;
			column-gap: 12px;
      img {
        width: 8px;
      }
      h5 {
        color: #30302F;
				font-family: "Inter";
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
      }
    }
    &_links {
      display: flex;
      flex-direction: column;
    }
    &_link {
      width: 100%;
      a {
        display: flex;
        align-items: center;
				margin-block: 16px;
				column-gap: 12px;
        img {
          width: 16px;
          height: 16px;
        }
        h4 {
          color: #696A68;
					font-family: Inter;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
        }
      }
    }

    &_dropdown {
      &_heading {
        display: flex;
        align-items: center;
        column-gap: 12px;
				margin-block: 12px;
        img {
          width: 8px;
          //   height: 1vw;
        }
        h5 {
          color: #30302F;
				font-family: "Inter";
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
        }
      }
      &_links {
        display: flex;
        flex-direction: column;
      }
      &_link {
        width: 100%;
				a {
					display: flex;
					align-items: center;
					margin-block: 16px;
					column-gap: 12px;
					img {
						width: 16px;
						height: 16px;
					}
					h4 {
						color: #696A68;
						font-family: Inter;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
					}
				}
      }
    }
  }
}
.sidenavmobile {
	display: none;
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.sidenavmobile {
		display: block;
	}
}
