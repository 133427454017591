.engineering {
	&_container {
		display: flex;
		justify-content: center;
		padding: 3vw 4vw;
		&_content {
			border-radius: 0.5vw;
			width: 100%;
			&_data {
				&_sections {
					padding: 9vw 5vw 3vw 5vw;
					&_top {
						width: 50%;
						margin-bottom: 3vw;
						h3 {
							font-size: 3.4vw;
							font-weight: 400;
							color: #fff;
						}
					}
					&_middle {
						width: 50%;
						margin-bottom: 3vw;
						h3 {
							font-size: 1.6vw;
							color: rgb(255, 255, 255, 90%);
							font-weight: 300;
						}
					}
					&_bottom {
						display: flex;
						justify-content: space-between;
						&_data {
							width: 42%;
							background: #61bcac;
							padding: 2vw 2.5vw;
							border-radius: 0.8vw;
							cursor: pointer;
							p {
								font-size: 2.2vw;
								font-weight: 400;
								color: #fff;
							}
							.hire {
								font-size: 2.2vw;
								font-weight: 400;
								color: #fff;
								margin-bottom: 1vw;
							}
							.talent {
								font-size: 1.7vw;
								font-weight: 400;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
}
.modalbutton {
	background: #61bcac;
	color: #fff;
	padding: 0.5vw;
	font-size: 1.4vw;
	border: none;
	width: 35%;
	cursor: pointer;
}

@media screen and (max-width: 760px) {
	// Please avoid !important unless it is absolutely necessary and avoid generic classnames

	// .MuiBox-root {
	// 	width: 285px !important;
	// 	border: none !important;
	// 	padding: 36px !important;
	// }
	// .MuiTypography-root {
	// 	font-size: 5.5vw !important;
	// 	text-align: center !important;
	// 	line-height: 1 !important;
	// 	padding: 20px !important;
	// }
	.modalbutton {
		background: #61bcac;
		color: #fff;
		padding: 3vw;
		font-size: 5.5vw;
		border: none;
		width: 100%;
		cursor: pointer;
		margin-bottom: 3vw;
	}
	.modal_button {
		display: flex;
		flex-direction: column !important;
	}
	.engineering {
		&_container {
			&_content {
				background-position: top !important;
				border-radius: 2vw;
				&_data {
					&_sections {
						padding: 15vw 5vw 3vw 5vw;
						&_top {
							width: 80%;
							margin-bottom: 5vw;
							h3 {
								font-size: 9vw;
								line-height: 1;
							}
						}
						&_middle {
							width: 80%;
							margin-bottom: 5vw;
							h3 {
								font-size: 4.3vw;
								font-weight: 500;
							}
						}
						&_bottom {
							flex-direction: column;
							&_data {
								margin-bottom: 5vw;
								border-radius: 2vw;
								width: 95%;
								p {
									font-size: 7vw;
								}
								.hire {
									font-size: 7vw;
								}
								.talent {
									font-size: 5.5vw;
								}
							}
						}
					}
				}
			}
		}
	}
}
