.profiledropdown {
display: flex;
flex-direction: column;
justify-content: center;


    .flex {
        position: relative;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &>a {
            display: flex;
            align-items: center;
            column-gap: 6px;
        }

        &_dropdown {
            box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
            background-color: #fff;
            position: absolute;
            top: 3vw;
            right: -3vw;
            z-index: 1;
            padding: 2vw;
            border-radius: 0.6vw;


            &_container {
                display: flex;
                flex-direction: column;
            }

            &_section {
                h6 {
                    color: #696A68;
                    font-family: Inter;
                    font-size: 1.1vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                &_content {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 5px 0 12px 0;
                    cursor: pointer;


                    &_left {
                        display: flex;
                        align-items: center;
                        padding: 8px 0 0 0;

                        input {
                            margin: 0;
                            cursor: pointer;
                        }

                        p {
                            color: #000;
                            font-size: 1vw;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            padding: 0 0 0 5px;
                        }
                    }
                }
            }


        }
    }

    .add-account-btn {
        cursor: pointer;
        border-radius: 8px;
        background-color: #3abf81;
        color: #fff;
        border: none;
        padding: 0.5vw 1vw;
        font-size: 1.1vw;
    }
}