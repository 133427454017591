.publicfriend {
	display: flex;
	flex-direction: column;
	align-items: center;
	&_container {
		width: 68%;
		padding: 0 0 4vw 0;
	}
	&_banner {
		width: 100%;
		img {
			width: 100%;
			height: 380px;
			object-fit: cover;
		}
	}
	&_user {
		padding: 0 0 3vw 0;
		&_details {
			padding: 2vw 0;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			&_left {
				display: flex;
				justify-content: center;
			}
			img {
				width: 7vw;
				height: 7vw;
				border-radius: 50%;
				object-fit: cover;
			}
			&_content {
				padding: 0 0 0 2vw;
				&_name {
					h3 {
						font-size: 1.6vw;
						font-weight: 600;
						padding-bottom: 0.4vw;
					}
				}
				&_follow {
					display: flex;
					span {
						font-size: 1.1vw;
						color: #696666;
					}
					p {
						padding: 0 0.5vw;
						font-size: 1.1vw;
						color: #696666;
					}
				}
				&_images {
					padding: 1vw 0 0 0;
					img {
						width: 2.5vw;
						height: 2.5vw;
					}
				}
			}
			&_right {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				padding-top: 1vw;
				&_buttonsec {
					width: 8vw;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 2vw;
					background: #61bcac;
					color: white;
					padding: 0.6vw 0vw;
					margin: 0.3vw;
					img {
						width: 1.2vw;
						height: 1.2vw;
					}
					p {
						font-size: 0.9vw;
						margin-left: 0.5vw;
					}
				}
			}
		}
		&_data {
			&_sections {
				display: flex;
				justify-content: center;
				&_right {
					width: 100%;
					&_tabsection {
						box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
						.tablist-custom {
							display: flex;
							justify-content: space-between;
							padding: 0.5vw 1vw 0 1vw;
							margin: 1.5vw 0 0;
							border-radius: 6px;
							background: #fff;
							// .tabimage {
							//     width: 12vw;

							//     img {
							//         width: 100%;
							//     }
							// }
						}
						.react-tabs__tab-list {
							border-radius: 0.3vw;
							border-bottom: none;
							margin: 0;
						}
						.react-tabs__tab {
							font-size: 1.4vw;
							font-style: normal;
							font-weight: 500;
							margin-right: 1.5vw;
							color: #000;
						}
						.react-tabs__tab--selected {
							border: none !important;
							border-bottom: 0.4vw solid #61bcac !important;
							padding-bottom: 1.3vw;
						}
						&_tabpanel1 {
							&_friendscards {
								display: flex;
								flex-wrap: wrap;
								padding: 2vw 0vw 3vw 0vw;
							}
							&_friendscard {
								width: 44%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
								border-radius: 0.2vw;
								margin: 1vw;
								padding: 0.8vw;
								position: relative;
								.friendscard-image {
									display: flex;
									align-items: center;
								}
								p {
									color: #9a9a9a;
									font-size: 1.1vw;
									font-style: normal;
									font-weight: 500;
									font-family: Poppins, sans-serif;
								}
								img {
									width: 4vw;
									height: 4vw;
									object-fit: cover;
									margin-right: 1vw;
									border-radius: 50%;
								}
								.friendscard-content {
									position: relative;
									img {
										width: 30%;
									}
								}
								.rectangle {
									background-image: url(../../assets/images/Rectangle.png);
									width: 12vw;
									height: 12vh;
									background-repeat: no-repeat;
									background-size: contain;
									justify-content: center;
									display: flex;
									align-items: center;
									position: absolute;
									bottom: -3vw;
									right: -7.5vw;
									z-index: 999;
									img {
										width: 1.2vw;
									}
									p {
										font-size: 1vw;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.publicfriend {
		&_container {
			width: 100%;
		}
		&_user {
			padding: 0 0 30px 0;
			display: flex;
			justify-content: center;
			flex-direction: column;
			&_details {
				padding: 2vw 0;
				display: flex;
				justify-content: center;
				flex-direction: column;
				&_left {
					display: flex;
					width: 100%;
					justify-content: center;
					flex-direction: column;
				}
				&_image {
					display: flex;
					justify-content: center;
					margin-top: 20px;
				}
				img {
					width: 150px;
					height: 150px;
					display: flex;
					justify-content: center;
				}
				&_content {
					padding: 0 0 0 10px;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					&_name {
						display: flex;
						justify-content: center;
						h3 {
							font-size: 36px;
							font-weight: 600;
							padding-bottom: 5px;
						}
					}
					&_follow {
						display: flex;
						align-items: center;
						span {
							font-size: 14px;
							color: #696666;
						}
						p {
							padding: 1px 4px 0 4px;
							font-size: 14px;
							color: #696666;
						}
					}
					&_images {
						padding: 10px 0 0 0;
						display: flex;
						flex-direction: row;
						img {
							width: 35px;
							height: 35px;
						}
					}
				}
				&_right {
					display: flex;
					justify-content: unset;
					align-items: center;
					width: 90%;
					padding: 10px;
					flex-wrap: wrap;
					margin-left: 10px;
					gap: 12px;
					&_buttonsec {
						flex: 48%;
						max-width: 48%;
						height: 30px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 25px;
						background: #61bcac;
						color: white;
						padding: 0.6vw 0vw;
						margin: 0;
						img {
							width: 15px;
							height: 15px;
						}
						p {
							font-size: 15px;
							margin-left: 5px;
						}
					}
				}
			}
			&_data {
				&_sections {
					&_right {
						&_tabsection {
							box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
							margin-inline: 12px;
							border-radius: 6px;
							.tablist-custom {
								display: flex;
								justify-content: space-between;
								padding: 0.5vw 1vw 0 1vw;
								margin: 1.5vw 0 0;
								border-radius: 6px;
								background: #fff;
								// .tabimage {
								//     width: 12vw;

								//     img {
								//         width: 100%;
								//     }
								// }
							}
							.react-tabs__tab-list {
								border-radius: 0.3vw;
								border-bottom: none;
								margin: 0;
							}
							.react-tabs__tab {
								color: #000;
								font-family: Poppins;
								font-size: 19px;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
							}
							.react-tabs__tab--selected {
								border: none !important;
								border-bottom: 3px solid #61bcac !important;
								padding-bottom: 1.3vw;
							}
							&_tabpanel1 {
								&_friendscards {
									display: flex;
									flex-wrap: wrap;
									justify-content: center;
									padding: 2vw 0 3vw 0;
									margin-top: 5px;
								}
								&_friendscard {
									width: 100%;
									display: flex;
									justify-content: space-between;
									align-items: center;
									box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
									border-radius: 5px;
									padding: 14px 16px;
									margin: 8px 12px;
									.friendscard-image {
										display: flex;
										column-gap: 8px;
										align-items: center;
									}
									img {
										width: 67px;
										height: 67px;
										object-fit: cover;
									}
									p {
										color: #9a9a9a;
										font-family: Poppins;
										font-size: 18px;
										font-style: normal;
										font-weight: 500;
										line-height: normal;
									}
									.friendscard-content {
										img {
											width: 30%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
