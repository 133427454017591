.sc {
	&_pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		&_container {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 2vw 0;
			ul {
				padding: 0;
				margin: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				li {
					list-style-type: none;
					width: 4vw;
					a {
						text-decoration: none;
						color: #61bcac;
					}
				}
				.active {
					a {
						color: #000000;
					}
				}
			}
		}
	}
	&_main {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		margin: 1vw 0 1vw 4vw;
		width: 8%;
		h6 {
			font-size: 0.7vw;
			color: #256e61;
			font-weight: 600;
		}
		img {
			width: 0.4vw;
			height: 1.2vh;
		}
	}
	&_pop {
		display: flex;
		justify-content: center;
		align-items: center;
		&_container {
			background-color: #ffffff;
			width: 46%;
			margin-top: 1vw;
			box-shadow: 0 0 8px #b9babd;
		}
		&_section {
			&_input {
				display: flex;
				width: 100%;
				justify-content: center;
				margin-top: 2vw;
				input {
					outline: none;
					width: 65%;
					padding: 0.5vw 0 0.5vw 0.8vw;
					font-size: 0.8vw;
					border-radius: 1vw;
					border: 1px solid #BEBEBE;
				}
			}
			&_heading {
				margin: 1vw 2vw 2vw;
				h3 {
					color: #727272;
					font-size: 1vw;
					font-weight: 600;
				}
			}
			&_cards {
				margin: 1vw;
				&_content:last-child {
					margin-bottom: 2vw;
				}
				&_content {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					margin: 1vw;

					&_center {
						padding: 0 1vw;
						display: flex;
						justify-content: flex-start;
						&_image {
							margin-right: 1.5vw;
							img {
								width: 4vw;
								height: 4vw;
								border-radius: 50%;
							}
						}

						&_content {
							display: flex;
							flex-direction: column;
							align-self: center;
							h2 {
								display: flex;
								align-items: center;
								font-size: 1.2vw;
								font-weight: 700;
								color: #000000;
								margin-bottom: 0.3vw;
							}
							h5 {
								font-size: 0.9vw;
								color: #5e5e5e;
								font-weight: 500;
							}
							p {
								color: #8e8e8e;
								font-size: 0.7vw;
								font-weight: 400;
								padding-top: 0.5vw;
							}
						}
					}
					&_right {
						display: flex;
						align-self: center;
						button {
							background-color: #61bcac;
							color: #ffffff;
							border-radius: 20px;
							border: none;
							padding: 0.3vw;
							font-size: 0.9vw;
							width: 8vw;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.sc {
		margin-top: 0;
		&_pagination {
			&_container {
				padding: 10px 0;
				ul {
					li {
						width: auto;
						padding: 0 12px;
					}
				}
			}
		}
		&_main {
			margin: 12px 0 12px 40px;
			width: 10%;
			h6 {
				font-size: 10px;
				color: #256e61;
				font-weight: 600;
			}
			img {
				width: 8px;
				height: 8px;
				margin: 0 10px 0 10px;
			}
		}
		&_pop {
			&_container {
				width: 100%;
				margin: 12px;
				border-radius: 4px;
			}
			&_section {
				&_input {
					width: 100%;
					margin-block: 20px;
					input {
						width: 100%;
						padding: 12px 24px;
						font-size: 12px;
						border-radius: 25px;
						margin: 0 20px;
						border: 1px solid #BEBEBE;
					}
				}
				&_heading {
					margin: 10px 20px 20px;
					h3 {
						color: #727272;
						font-size: 14px;
						font-weight: 600;
					}
				}
				&_cards {
					margin: 10px;
					&_content:last-child {
						margin-bottom: 30px;
					}
					&_content {
						margin: 12px;
						&_center {
							padding: 0 1vw;
							display: flex;
							justify-content: flex-start;
							&_image {
								margin-right: 20px;
								img {
									width: 40px;
									height: 40px;
									border-radius: 50%;
								}
							}
							&_content {
								h2 {
									font-size: 12px;
								}
								h5 {
									font-size: 10px;
									color: #5e5e5e;
									font-weight: 500;
								}
								p {
									color: #8e8e8e;
									font-size: 8px;
									font-weight: 400;
									padding-top: 0.5vw;
								}
							}
						}
						&_right {
							button {
								border-radius: 25px;
								padding: 4px 2px;
								font-size: 10px;
								width: 70px;
							}
						}
					}
				}
			}
		}
	}
}
