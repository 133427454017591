.home {
  .ptag {
    margin-top: 1vw;
    margin-left: 1vw;
    width: 100%;
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 500;
  }

  display: flex;
  flex-direction: column;
  width: 100%;

  &_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &_sections {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }

  &_mobile {
    display: none;
  }

  &_center {
    grid-column: span 6;
		max-height: 90dvh;

		@media screen and (min-width: 768px) {
			max-height: inherit;
			padding: 24px 12px;
			margin-bottom: 24px;
		}

    &_chat {
      margin: 1vw 0;
      border-radius: 0.3vw;

			@media screen and (min-width: 768px) {
				margin: 0 0 16px 0;
			}

      &_container {
        padding: 0.6vw 0.6vw 2vw 0.6vw;
        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &_content {
        width: -webkit-fill-available;
        background: white;
        padding: 1vw 1vw;
        height: 5vw;
        border-radius: 0.3vw;
        border: 1px solid #d1c3c3;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: auto;

        ::-webkit-scrollbar {
          border-radius: 2px;
          width: 10px;
        }

        scrollbar-width: thin;
        scrollbar-color: #61bcac #ededed;

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgb(58, 116, 58);
          border-radius: 4px;
        }

        .img {
          width: 100%;
          display: flex;
          justify-content: center;

          .loader {
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
          }

          @-webkit-keyframes spin {
            0% {
              -webkit-transform: rotate(0deg);
            }

            100% {
              -webkit-transform: rotate(360deg);
            }
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(360deg);
            }
          }
        }

        p {
          height: -webkit-fill-available;
          font-size: 1vw;
          color: #9f9f9f;
        }
      }

      &_message {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.4vw 0 0 0;
        position: relative;

        &_input {
          width: 84%;
          position: relative;
          display: flex;
          align-items: center;
          border-radius: 2vw;
          border: 1px solid #d1c3c3;

          img {
            position: absolute;
            left: 0.7vw;
            width: 2vw;
          }

          input {
            width: 100%;
            padding: 0.6vw 0 0.6vw 1vw;
            border: none;
            border-radius: 3vw;
            font-size: 1vw;
            color: #9f9f9f;
          }
        }

        .pencil.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        .pencil {
          background: #61bcac;
          width: 8%;
          height: 1.2vw;
          padding: 0.6vw 1.2vw;
          border-radius: 1.5vw;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .pencil_img {
          width: 1.2vw;
        }

        .pencil-main {
          position: absolute;
          right: -5vw;
          width: 1.6vw;
          height: 1.6vw;
        }
      }
    }
  }
}

.open-class {
  display: none;
}

@media screen and (max-width: 768px) {
  .open-class {
    display: block;
  }

  .home {
    &_sections {
      flex-direction: column;
      width: 100%;
      padding: 20px 15px;
    }

    &_mobile {
      display: flex;
      flex-direction: column;
      margin-bottom: 3vw;
      border-bottom: 2px solid #ececec;

      padding: 3vw 0;

      &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.14);
        margin-bottom: 3vw;
        padding: 2vw 4vw;
        position: relative;

        &_input {
          width: 90%;
          display: flex;

          img {
            width: 100%;
          }

          .css-148wedx-MuiFormControl-root-MuiTextField-root {
            width: 80%;
            border: 2px solid black !important;
          }
        }
      }

      .settings-logout {
        position: absolute;
        right: 5vw;
        top: 12vw;

        &_contain {
          display: flex;
          background: white;
          padding: 2vw;
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.14);

          h5 {
            margin-left: 2vw;
          }
        }
      }

      &_bottom {
        width: 100%;
        padding: 0 5vw;
      }

      hr {
        border: none;
        background: #ececec;
        width: 100%;
        height: 2px;
      }

      ul {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // margin-top: 15px;

        img {
          width: 6vw;
        }

        span {
          font-size: 10px;
        }
      }

      .mobilenotifications {
        position: relative;
        display: flex;
        flex-direction: column;

        &_dropdown {
          width: 50%;
          box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
          background-color: #fff;
          position: absolute;
          // top: 6vw;
          // left: 20vw;
          top: 42vw;
          right: 3vw;
          z-index: 1;
          padding: 2vw;
          border-radius: 1vw;

          &_container {
            display: flex;
            flex-direction: column;

            h5 {
              font-size: 3.5vw;
              font-weight: 500;
              margin-bottom: 2vw;
            }
          }

          &_section {
            margin-bottom: 0.5vw;

            h6 {
              font-size: 2.8vw;
              color: #256f61;
              font-weight: 500;
            }

            &_content {
              display: flex;
              justify-content: flex-start;
              margin: 1.5vw 0;

              &_left {
                width: 8vw;

                img {
                  border-radius: 50%;
                  width: 100%;
                }
              }

              &_right {
                min-width: max-content;
                margin-left: 1vw;

                p {
                  font-size: 2.5vw;
                }

                span {
                  font-size: 2.5vw;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }

    &_center {
      width: 100%;
			margin-inline: 12px;

      &_chat {
        margin: 0 0 18px 0;

        &_container {
          padding: 10px 5px;
          // margin: 2vw 0;
        }

        &_content {
          // padding: 20px 10px;
          border-radius: 8px;
          height: 150px;

          p {
            margin: 10px 10px;
            height: -webkit-fill-available;
            font-size: 17px;
            font-family: "Poppins", sans-serif;
          }
        }

        &_message {
          padding: 16px 0 0 0;

          &_input {
            width: 100%;
            border: none;

            input {
              width: 60%;
              padding: 8px 8px 8px 30px;
              font-size: 15px;
              border-radius: 20px;
              border: 1px solid #d1c3c3;
              font-family: "Poppins", sans-serif;
              font-size: 15px;
            }

            img {
              left: 10px;
              width: 30px;
              height: 30px;
            }
          }

          .pencil {
            position: absolute;
            right: 0;
            width: 47px;
            height: 20px;
            padding: 10px 12px;
            border-radius: 30px;
          }

          .pencil_img {
            width: 16px;
          }
        }
      }
    }
  }
}