.review {
  // background: #f9f9f9;
  // margin: 2vw 0 0 0;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0.5vw 1vw 1vw 0.5vw;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
  &_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_left {
      display: flex;
      align-items: center;
      img {
        width: 2.5vw;
        height: 2.5vw;
        border-radius: 50%;
        object-fit: cover;
      }
      &_user {
        padding: 0 0 0 1vw;
        &_name {
          h6 {
            font-size: 0.9vw;
            color: #4669c1;
          }
        }
        &_date {
          display: flex;
          align-items: center;
          padding: 0.2vw 0 0 0;
          p {
            font-size: 0.8vw;
            color: #404040;
            padding: 0 0.6vw 0 0;
          }
          span {
            font-size: 0.8vw;
            color: #787878;
          }
        }
      }
      &_stars {
        display: flex;
        margin-left: 2vw;
      }
      .MuiRating-root {
        font-size: 1vw;
      }
    }
    &_right {
      cursor: pointer;
      img {
        width: 2vw;
        height: 2vw;
      }
    }
  }
  &_content {
    font-size: 0.9vw;
    display: flex;
    flex-direction: column;
    padding: 1.5vw 1vw;
    color: #6c6c6c;
  }
  &_bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #d7d7d7;
    padding: 1vw 0 0;
    &_like {
      display: flex;
      align-items: center;
      img {
        width: 1.4vw;
        height: 1.4vw;
        padding: 0;
      }
      p {
        padding: 0 0 0 0.5vw;
        font-size: 0.9vw;
        color: #6a6a6a;
      }
    }
    &_comment {
      width: 100%;
      display: flex;
      p {
        width: 100%;
        font-size: 1vw;
        font-weight: 500;
        border: none;
        color: #5a5757;
        outline: none;
        resize: none;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      img {
        width: 1.8vw;
        height: 1.8vw;
        object-fit: cover;
        border-radius: 50%;
      }
      p {
        font-size: 0.9vw;
        padding: 0 0 0 0.5vw;
        color: #6c6c6c;
        font-weight: 400;
      }
    }
  }
}

.popup {
  color: #6c6c6c;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 44%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0.5vw;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;

  &_top {
    border-radius: 10px;
    border: 1px solid #d1c3c3;
    padding: 0rem 0.5rem 2rem;
    &_rating {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8vw 0;
      .mobilepostbutton {
        display: none;
      }
      .MuiRating-root {
        font-size: 1vw;
      }
    }
    &_content {
      display: flex;
      justify-content: space-between;
      &_text {
        width: 80%;
        font-size: 0.9vw;
      }
      &_postbuttons {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: flex-end;
      }
      &_postbutton {
        margin: 0.3vw;
        font-size: 0.9vw;
        height: 1.2vw;
        width: 7vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2vw;
        background: #61bcac;
        color: white;
        padding: 0.5vw 0.2vw;
        cursor: pointer;
      }
    }
  }
  &_bottom {
    display: flex;
    justify-content: space-between;
    margin: 1vw 0;
    &_type{
      width:86%
     }
    .hemant {
      padding: 0.5vw 0.8vw 0.5vw 0.8vw;
      width: 96%;
      background: #fff;
      font-family: Inter;
      font-size: 0.9vw;
      border-radius: 30px;
      border: 1px solid #ccc;
      color: #5a5757;
      outline: none;
      resize: none;
      display: flex;
      align-items: center;
    }
    .count {
      width: 100%;
      display: flex;
      font-weight: 500;
      justify-content: flex-end;
      font-size: 0.8vw;
      color: #5a5757;
    }
    .loader {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      -webkit-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    &_image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12%;
      height: 12%;
      padding: 0.5vw 0;
      border-radius: 2vw;
      background: #61bcac;
      cursor: pointer;
      img {
        width: 30%;
        height: 30%;
      }
    }
  }
}
.popupclose {
  height: 2vw;
  position: fixed;
  top: -2.5vw;
  right: -5px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  img {
    cursor: pointer;
    border-radius: 50%;
    background-color: #d9d9d9;
  }
}
.blurred-background {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  z-index: 998;
}
.modalbutton {
  background: #61bcac;
  color: #fff;
  padding: 0.5vw;
  font-size: 1vw;
  border: none;
  width: 35%;
  cursor: pointer;
  border-radius: 1vw;
}

@media screen and (max-width: 760px) {
	.review {
		padding: 0;
		// margin: 20px 0;

		&_heading {
			padding: 4vw 15px;

			&_left {
				img {
					width: 46px;
					height: 46px;
				}

				&_user {
					&_name {
						h6 {
							font-size: 15px;
						}
					}

					&_date {
						p {
							font-size: 12px;
						}

						span {
							font-size: 12px;
						}
					}
				}

				&_stars {
					display: flex;
					.MuiRating-root {
						font-size: 5vw;
						margin-left: 3vw;
					}
				}
			}

			&_right {
				display: none;
				img {
					width: 16px;
					height: 16px;
				}
			}
		}

		&_content {
			padding: 0;
			font-size: 16px;
			border-bottom: 2px solid #e7e7e7;
			margin: 0 5vw;
			padding-bottom: 2vw;
			&_image {
				padding: 12px 24px;
				height: auto;

				img {
					height: 180px;
					object-fit: cover;
				}
			}

			&_current {
				background: white;
				padding: 12px 0;

				&_count {
					img {
						width: 18px;
						height: 18px;
					}

					p {
						display: block !important;
						font-size: 12px;
					}
				}

				p {
					display: none;
				}
			}
		}

		&_bottom {
			background: white;
			border: none;
			padding: 2vw 0;

			&_like {
				background: #f9f9f9;
				padding: 4px 10px;
				border-radius: 10px;

				img {
					width: 16px;
					height: 16px;
				}

				p {
					font-size: 12px;
				}
			}

			&_comment {
				// background: #f9f9f9;
				padding: 4px 10px;
				border-radius: 10px;

				img {
					width: 35px;
					height: 35px;
				}

				p {
					font-size: 14px;
					margin-left: 1vw;
				}
			}

			&_share {
				background: #f9f9f9;
				padding: 4px 10px;
				border-radius: 10px;

				img {
					width: 16px;
					height: 16px;
				}

				p {
					font-size: 12px;
				}
			}
		}
	}
}
