.heading {
	background: #f5f5f5;

	&_container {
		display: flex;
		justify-content: center;
	}

	&_sections {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&_left {
		width: 100%;
		display: flex;
		align-items: center;

		.img {
			border-right: 1px solid #cbc3c3;

			cursor: pointer;
		}

		&_content {
			display: flex;
			margin-inline-start: 12px;
			column-gap: 12px;

			align-items: center;
			cursor: pointer;

			.image,
			&>svg {
				display: flex;
				align-items: center;
				height: 22px;
			}

			h3 {
				color: #256F61;
				font-family: Inter;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}
	}

	&_right {
		width: 20%;
		padding-left: 25px;
	}

	&_mobile {
		display: none;
	}

	&_middle {
		display: flex;
		justify-content: space-between;
		width: 18%;

		.upload {
			border: 1px solid #bebebe;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.2vw 1.5vw;
			background: #fff;
			border-radius: 1vw;
			font-size: 0.9vw;
			font-weight: 500;
		}

		&_content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 45%;

			img {
				width: 1.5vw;
				height: 1.5vw;
			}
		}
	}

	// &_user {
	// 	ul {
	// 		display: flex;
	// 		justify-content: center;
	// 		align-items: center;

	// 		li.flex a {
	// 			display: flex;
	// 			align-items: center;
	// 			
	// 		}
	// 	}

	// 	a {
	// 		color: #605656;
	// 		margin-left: 5vw;
	// 	}

	// 	p {
	// 		font-size: 1vw;
	// 		margin-left: 0.5vw;
	// 	}
	// }

	&_user {
		margin-inline-start: auto;
		ul {
			display: flex;
			justify-content: center;
			align-items: center;
			column-gap: 10px;
			li {
				display: flex;
				a {
					display: flex;
					align-items: center;
					color: #605656;
				}
			}


			.flex {
				position: relative;
				display: flex;
				flex-direction: column;
				cursor: pointer;

				& > a {
					display: flex;
					align-items: center;
					column-gap: 6px;
				}

				&_dropdown {
					box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
					background-color: #fff;
					position: absolute;
					top: 3vw;
					right: -3vw;
					z-index: 1;
					padding: 2vw;
					border-radius: 0.6vw;
					width: 10vw;

					&_container {
						display: flex;
						flex-direction: column;
					}

					&_section {
						h6 {
							color: #696A68;
							font-family: Inter;
							font-size: 1.1vw;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}

						&_content {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							padding: 1vw 0;
							

							&_left {
								width: 1vw;
								height: 1vw;

								input {
									margin: 0;
								}
							}

							&_right {
								min-width: max-content;
								margin-left: 0.5vw;

								p {
									color: #000;
									font-family: Inter;
									font-size: 0.9vw;
									font-style: normal;
									font-weight: 400;
									line-height: normal;
								}
							}
						}
					}

					.add-account-btn {
						cursor: pointer;
						border-radius: 2vw;
						background-color: #3abf81;
						color: #fff;
						border: none;
						padding: 0.5vw;

					}
				}
			}
		}
	}

	&_logo {
		display: none;
		background: #fcfcfc;

		&_content {
			display: flex;
			align-items: center;
			padding: 1vw;

			.image {
				display: flex;
				align-items: center;
				width: 1.5vw;
				height: 1.5vw;
				padding-left: 1vw;
				padding-right: 1vw;
				margin-right: 1vw;
			}

			h3 {
				color: #256e61;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.heading {
		&_container {
			display: flex;
			justify-content: center;
			flex-direction: column;
		}

		&_sections {
			padding-inline: 12px;
			width: -webkit-fill-available;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&_left {
			display: flex;
			justify-content: space-between;
			margin-block: 0;

			.img {
				margin: 0;
				width: 100%;
				border: none;
			}

			&_content {
				display: none;

				h3 {
					font-size: 19px;
				}
			}
		}

		&_right {
			display: none;
			width: 30%;
		}

		&_mobile {
			display: flex;
			a {
				color: #605656;
				margin-left: 2vw;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			p {
				margin-left: 0.5vw;
				font-family: Inter;
				font-size: 18px;
			}
		}

		&_middle {
			display: flex;
			justify-content: space-between;
			width: 18%;

			.upload {
				border: 1px solid #bebebe;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0.2vw 1.5vw;
				background: #fff;
				border-radius: 1vw;
				font-size: 0.9vw;
				font-weight: 500;
			}

			&_content {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 45%;

				img {
					width: 1.5vw;
					height: 1.5vw;
				}
			}
		}

		&_user {
			display: none;
		}

		&_logo {
			display: block;
			background: inherit;

			&_content {
				column-gap: 12px;
				padding: 0;
				.image {
					display: flex;
					align-items: center;
					margin: 0;
					padding: 0;
					width: 24px;
					height: 24px;
				}

				h3 {
					color: #256E61;

					/* font-weight-medium-lg */
					font-family: Inter;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
			}
		}
	}
}