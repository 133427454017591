$base-font-size: 16px;

// colors

$primary_white: #ffffff;
$primary_black: #000000;
$primary_blue: #263238;
$primary_grey: #ededed;
$white_shadow: #ffffff90;
$grey_input: #a8adaf;
$secondary_grey: #d4d6d7;
$font_grey: #677074;

$bg_light_grey: #fbfbfb;
$bg_black_shadow: #00000020;

//Font-siz2
$font_top1: 20vw;
$font_top2: 16vw;
$font_top3: 14vw;
$font_top4: 12vw;
$font_top5: 10vw;
$font_top6: 8vw;
$font_top7: 6vw;
$font_top8: 5vw;
$font_top9: 4vw;
$font_top10: 3vw;
$font_xxlarge: 2.4vw;
$font_xlarge: 2vw;
$font_large: 1.8vw;
$font_regular: 1.6vw;
$font_button: 1.5vw;
$font_medium: 1.4vw;
$font_text: 1.3vw;
$font_small: 1.2vw;
$font_xxsmall: 1.1vw;
$font_xsmall: 1vw;
$font_verysmall: 0.9vw;
$font_xverysmall: 0.5vw;

@function toRem($value) {
	@return $value / $base-font-size * 1rem;
}

$spacing: (
	xss: toRem(2px),
	xs: toRem(4px),
	xsl: toRem(6px),
	sm: toRem(8px),
	sm1: toRem(10px),
	sm2: toRem(12px),
	md: toRem(14px),
	md1: toRem(16px),
	md2: toRem(18px),
	md3: toRem(20px),
	md4: toRem(22px),
	lg: toRem(24px),
	lg1: toRem(28px),
	xl: toRem(32px),
	xl1: toRem(36px),
	xl2: toRem(44px),
	xxl: toRem(48px),
	xxl1: toRem(54px),
	xxxl: toRem(64px),
	xxxl1: toRem(74px),
);

$borderRadius: (
	4: toRem(4),
	6: toRem(6),
	8: toRem(8),
	12: toRem(12),
	18: toRem(18),
	24: toRem(24),
	32: toRem(32),
	40: toRem(40),
);

$pGreen : #61BCAC;

$pWhite: #ffffff;
$pBlack: #000000;
$pGreen: #61BCAC;
$pGreenFaded: #E4F6F3;

$sWhite: #F5F5F5;
$sBlack: #2B2A2A;
$sBlackFaded: #30302F;
$sGreen: #256F61;

$gray: #767575;
$grayFaded: #797979;
$lightGray: #848484;
$lightGrayFaded: #3d3d3d;
$strokedGray: #585858;
$dimGray: #989898;
$dimGrayFaded: #f3f3f3;
$dimGrayLight: #D9D9D9;
$dimGrayExtraLight: #767070;

$borderGray: #d0cfcf;
$borderDimGray: #D6CECE;
$borderDimGrayLight: #CDCDCD;

$pLabel: #4D4D4D;
$error: #d32f2f;

@function spacing($key) {
	@return map-get($spacing, $key);
}

@function borderRadius($key) {
	@return map-get($borderRadius, $key);
}
