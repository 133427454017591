.mbsubheader {
    .react-tabs {
        width: 100% !important;
    }

    .react-tabs__tab-list {
        display: flex;
        width: 100% !important;
        justify-content: center;
        align-items: center;
        background: #61bcac;
        padding: 1vw 0 0vw;
        border-bottom: 1px solid #DCCECE;
    }

    .react-tabs__tab {
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 300;
        color: white;
        margin: 0;
    }

    .react-tabs__tab--selected {
        background: none;
        padding-bottom: 10px;
        border-bottom: 5px solid #2D7D6F !important;
        font-weight: 600;
        color: white;
    }

    width: 100%;
    // background: #61bcac;
    display: flex;
    align-items: center;
    justify-content: center;

    &_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_tablist-container {
        width: 95%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_left {
        width: 66%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_search {
        width: 25%;
        padding-bottom: 0.5vw;
    }

    &_tabsection {
        width: 70%;
        display: flex;
        justify-content: flex-end;
    }

    &_right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        p {
            color: white;
            border-radius: 5px;
            background: #256F61;
            padding: 0.3vw 0.8vw;
        }

        img {
            display: none;
        }
    }

    &_rightmob {
        // display: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_container {
            display: none;
        }

        ul {
            display: flex;
        }

        li {
            padding: 0.5vw 1.5vw;

            a {
                color: white;
            }
        }
    }


}







@media screen and (min-width: 320px) and (max-width: 760px) {
    .mbsubheader {
        .tabsection {
            width: 100%;
        }

        .react-tabs__tab-list {
            border: none;
            border-radius: 0.3vw;
            // border-bottom: none;
            background-color: white;
        }

        .react-tabs__tab {
            margin-left: 2vw;
            color: #858585;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 6px 12px 6px 0;
        }

        .react-tabs__tab--selected {
            border: none !important;
            border-bottom: 4px solid #61bcac !important;
            padding-bottom: 1vw;
            color: black;
        }

        &_tablist-container {
            flex-direction: column-reverse;
        }

        &_left {
            width: 100%;
            margin: 2vw 0 ;


            li {
                margin: 0 !important;
            }

            li:last-child {
                display: none;
            }
        }

        &_search {
            display: none;
        }

        &_tabsection {
            width: 100%;
            justify-content: space-between;
        }

        &_right {
            width: 100%;
            justify-content: space-between;
            margin-top: 2vw;

            p {
                padding: 1vw 2vw;
            }

            img {
                display: flex;
                width: 4vw;
            }
        }
    }


}