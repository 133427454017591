
.backdrop {
  position: fixed;
  // background-color: black;
  background-color: rgba(255, 255, 255, 0.91);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  display: flex;
justify-content: center;
align-items: center;
  position: fixed;
  bottom: 0.2vw;
  width: 100%;
  height: 80px;
}

.controls-upper-area {
  display: none;
  text-align: center;
}

.slider {
  width: 50%;
}

.button-area {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 1vw;
}
/* button {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  background-color: black;
  color: white;
  border: 1px solid yellow;
  font-size: 20px;
} */

.cancelbtn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18vw;
  height: 2.5vw;
  border: none;
  border-radius: 2vw;
  background: #d9d9d9;
  color: #857c7c;
  margin-right: 3vw;
  cursor: pointer;
  font-size: 1.6vw;
  padding: 1.8vw;



}

.cropbtn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18vw;
  height: 2.5vw;
  border: none;
  border-radius: 2vw;
  background: #61bcac;
  color: white;
  font-size: 1.6vw;
  padding: 1.8vw;
  cursor: pointer;
}

.imageCard {
  text-align: center;
}

.imageCard img {
  width: 600px;
}



@media screen and (max-width:760px){
  .cancelbtn{
    width: 30vw;
    padding: 4vw;
    border-radius: 30px;
    font-size: 16px;
  }
  .cropbtn{
    width: 30vw;
    padding: 4vw;
    border-radius: 30px;
    font-size: 16px;

  }
}