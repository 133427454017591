.pp {
	background-color: #fff;
	display: flex;
	justify-content: center;
	width: 100%;
	&_container {
		display: flex;
		justify-content: center;
		width: 70%;
		margin: 0 0 4vw;
		&_data {
			padding: 20px;
			h4 {
				padding: 1.5vw 0;
				font-size: 1.5vw;
				font-weight: 700;
			}
			h3 {
				padding: 0.5vw 0 0.5vw;
				font-size: 1vw;
			}
			span {
				font-size: 0.9vw;
				font-weight: 700;
			}
			p {
				padding: 0.3vw 0;
				font-size: 0.9vw;
			}
			a {
				color: blue;
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
}
